/* eslint-disable eqeqeq */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Flex,
  Grid,
  Button,
  Input,
  Modal,
  Card,
  Popover,
  Spinner,
  Tag,
  Radio,
  Text,
  Paragraph,
  Tooltip,
  Skeleton,
  Empty,
  useToast,
} from '@beachfront/ui'
import {
  FilterFilled,
  TrafficOutlined,
  UserOutlined,
  CloseOutlined,
  DragDropOutlined,
} from '@beachfront/ui/icons'
import { Field } from '@beachfront/ui/forms'
import { useQueryClient } from '@tanstack/react-query'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { api } from '../../../client-api'
import { useFetch, useUserData, useSegmentProviders } from '../../../hooks'
import { SEGMENT_TYPE } from '../../../enums'
import {
  isNotEmptyArray,
  isNotEmptyObject,
  isNumber,
  numberFormatter,
  getErrorMessage,
  formatCurrency,
} from '../../../utils'
import { CommonSpinner } from '../../../components'
import { getSegmentKeys, getParentProvider } from '..'

import ProviderFilterTitle from './provider-filter-title'

const AudienceModal = ({
  values,
  id,
  dspDeal,
  pmpAvails,
  adminCreate,
  showModal,
  onModalClose,
  onSuccessCallback,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { providers } = useSegmentProviders()
  const [activeParentProvider, setActiveParentProvider] = useState(null)
  const [load, setLoad] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [includeSegmentData, setIncludeSegmentData] = useState('')
  const [availsData, setAvailsData] = useState({})
  const [user] = useUserData()
  const [allData, setAllData] = useState({
    uploaded: [],
    shared: [],
    owned: [],
    include: [],
    exclude: [],
  })

  const selectedIds = useMemo(() => {
    const includeIds = allData.include.map((dt) => dt.id.toString())
    const excludeIds = allData.exclude.map((dt) => dt.id.toString())
    return [...includeIds, ...excludeIds]
  }, [JSON.stringify(allData.include), JSON.stringify(allData.exclude)])

  const isSegmentIncluded =
    !values.audienceBooleanLogic.enabled && values.includeSegmentAnd === 'AND'

  const segmentCost = useFetch({
    request: load ? api.mediaPlan.segmentCost : null,
    payload: {
      data: selectedIds,
      includeSegmentAnd: isSegmentIncluded,
    },
  })

  useEffect(() => {
    const inSeg = isNotEmptyArray(values.includeSegments)
      ? getSegmentKeys(values.includeSegments).map((el) => el.id)
      : []
    const exSeg = isNotEmptyArray(values.excludeSegments)
      ? getSegmentKeys(values.excludeSegments).map((el) => el.id)
      : []
    api.mediaPlan.availsDataList({ inSeg, exSeg }).then(
      (res) => {
        setAvailsData(res.data)
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
    setIncludeSegmentData(values.includeSegmentAnd)
  }, [])

  useEffect(() => {
    const allDataObject = {
      ...allData,
      include: isNotEmptyArray(values.includeSegments)
        ? [...values.includeSegments]
        : [],
      exclude: isNotEmptyArray(values.excludeSegments)
        ? [...values.excludeSegments]
        : [],
    }

    setAllData(allDataObject)
    setLoad(true)
  }, [
    JSON.stringify(values.includeSegments),
    JSON.stringify(values.excludeSegments),
  ])

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const isValidSource =
      source.droppableId === 'uploaded' ||
      source.droppableId === 'shared' ||
      source.droppableId === 'owned'
    const isValidDestination =
      destination.droppableId === 'include' ||
      destination.droppableId === 'exclude'

    if (isValidSource && isValidDestination) {
      const allDataObject = { ...allData }
      const sourceArr = allDataObject[source.droppableId] || []
      const destArr = allDataObject[destination.droppableId] || []

      if (sourceArr[source.index]) {
        const sourceItem = sourceArr[source.index]
        sourceArr[source.index].selected = true

        allDataObject[source.droppableId] = sourceArr

        if (source.droppableId === 'owned') {
          sourceItem.owned = true
        }

        // sourceItem.originIndex = source.index;
        destArr.splice(destination.index, 0, sourceItem)
        allDataObject[destination.droppableId] = destArr
      }

      setAllData(allDataObject)
    }
  }

  const onRemoveSegment = (type, item) => {
    let origin

    if (item.source === 0) {
      origin = 'shared'
    } else if (item.source === 1) {
      origin = 'uploaded'
    }

    const allDataObject = { ...allData }
    allDataObject[type] = allData[type].filter((el) => el.id !== item.id)

    const removeItemIndex = allDataObject[origin]?.findIndex(
      (el) => el.id == item.id
    )

    if (removeItemIndex >= 0) {
      allDataObject[origin][removeItemIndex].selected = false
    } else {
      origin = 'owned'
      const ownedremoveItemIndex = allDataObject[origin]?.findIndex(
        (el) => el.id == item.id
      )
      if (ownedremoveItemIndex >= 0) {
        allDataObject[origin][ownedremoveItemIndex].selected = false
      }
    }
    setAllData(allDataObject)
  }

  useEffect(() => {
    if (isNotEmptyObject(availsData)) {
      const allDataObject = { ...allData }
      for (let i = 0; i < allDataObject.include?.length; i++) {
        if (
          JSON.stringify(allDataObject.include[i]?.id) ===
          JSON.stringify(availsData?.inSeg[i]?.key)
        ) {
          if (!allDataObject.include[i].dailyAvails) {
            allDataObject.include[i].dailyAvails = availsData?.inSeg[i]?.value
          }
        }
      }
      for (let i = 0; i < allDataObject.exclude?.length; i++) {
        if (allDataObject.exclude[i]?.id == availsData?.exSeg[i]?.key) {
          if (!allDataObject.exclude[i].dailyAvails) {
            allDataObject.exclude[i].dailyAvails = availsData?.exSeg[i]?.value
          }
        }
      }
      setAllData(allDataObject)
    }

    let parentProvider = null
    if (isNotEmptyArray(allData.include)) {
      parentProvider = getParentProvider(allData.include[0], providers)
    } else if (isNotEmptyArray(allData.exclude)) {
      parentProvider = getParentProvider(allData.exclude[0], providers)
    }

    setActiveParentProvider(parentProvider)
  }, [
    JSON.stringify(availsData),
    JSON.stringify(allData.include),
    JSON.stringify(allData.exclude),
  ])

  const onSubmit = () => {
    if (id && !dspDeal) {
      setSubmitting(true)

      const request = adminCreate
        ? api.common.updateSegmentAvails
        : api.mediaPlan.segmentUpdate

      const postData = {
        includeSegmentAnd: isSegmentIncluded,
        includeSegments: isNotEmptyArray(allData.include)
          ? getSegmentKeys(allData.include)
          : [],
        excludeSegments: isNotEmptyArray(allData.exclude)
          ? getSegmentKeys(allData.exclude)
          : [],
      }
      values.includeSegments = isNotEmptyArray(allData.include)
        ? allData.include
        : []
      values.excludeSegments = isNotEmptyArray(allData.exclude)
        ? allData.exclude
        : []
      if (adminCreate) {
        postData.title = values.title
      }

      return request(id, postData)
        .then(
          (res) => {
            if (res.data?.success) {
              queryClient.invalidateQueries({ queryKey: ['targeting'] })
              toast.success({ title: 'Audience segment updated.' })
              onModalClose()
            } else {
              toast.error({
                title:
                  res.data?.msg ||
                  res.data.errorDetails ||
                  'Unable to update this audience segment. Please try again.',
              })
            }
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
          }
        )
        .finally(() => setSubmitting(false))
    } else {
      onSuccessCallback({
        include: allData.include,
        exclude: allData.exclude,
      })
      onModalClose()
    }
  }
  const updateIdsToString = (array) => {
    if (isNotEmptyArray(array)) {
      array.forEach((obj) => {
        obj.id = obj.id.toString()
      })
    }
  }
  const handleSegmentUpdate = () => {
    const compareSegments = (segments1, segments2) => {
      if (segments1.length !== segments2.length) {
        return false
      }

      segments1.sort((a, b) => a.id.localeCompare(b.id))
      segments2.sort((a, b) => a.id.localeCompare(b.id))

      for (let i = 0; i < segments1.length; i++) {
        if (segments1[i]?.id !== segments2[i]?.id) {
          return false
        }
      }
      return true
    }

    updateIdsToString(allData.include)
    updateIdsToString(allData.exclude)

    const includeSegmentsMatch = compareSegments(
      Object.hasOwn(values, 'includeSegments') ? values.includeSegments : [],
      allData.include
    )
    const excludeSegmentsMatch = compareSegments(
      Object.hasOwn(values, 'excludeSegments') ? values.excludeSegments : [],
      allData.exclude
    )
    return (
      includeSegmentsMatch &&
      excludeSegmentsMatch &&
      values.includeSegmentAnd === includeSegmentData
    )
  }

  const headerTitle = (
    <Flex justifyContent='space-between'>
      <Box my='auto'>Manage Audience Segments</Box>
      <Flex my='auto' gap={2} fontSize={1} fontFamily='body'>
        <Box my='auto'>
          <Text strong>Data Fees:</Text>
        </Box>
        <Flex
          px={2}
          height={35}
          minWidth={100}
          alignItems='center'
          justifyContent='center'
          border='solid 2px'
          borderRadius='base'
          borderColor='primary.2'
          bg='primary.light'
        >
          {segmentCost.loading ? (
            <Spinner size='small' my='auto' />
          ) : (
            <Text color='primary.base'>{`${
              segmentCost.data
                ? formatCurrency(Math.round(segmentCost.data * 100) / 100)
                : formatCurrency(0)
            } CPM`}</Text>
          )}
        </Flex>
      </Flex>
      <Flex gap={2}>
        <Button
          type='primary'
          disabled={submitting || handleSegmentUpdate()}
          loading={submitting}
          onClick={onSubmit}
        >
          {id && !dspDeal ? 'Update' : 'Add'}
        </Button>
        <Button onClick={onModalClose}>Close</Button>
      </Flex>
    </Flex>
  )

  return (
    <Modal
      visible={showModal}
      centered
      closable={false}
      bodyStyle={{ height: 600, overflow: 'auto' }}
      width='85%'
      footer={null}
      title={headerTitle}
    >
      {load && showModal ? (
        <AudienceProvider
          onDragEnd={onDragEnd}
          values={values}
          providers={providers}
          user={user}
          onRemoveSegment={onRemoveSegment}
          allData={allData}
          setAllData={setAllData}
          pmpAvails={pmpAvails}
          activeParentProvider={activeParentProvider}
        />
      ) : null}
      {!load ? <CommonSpinner /> : null}
    </Modal>
  )
}

const AudienceProvider = ({
  activeParentProvider,
  providers,
  allData,
  pmpAvails,
  user,
  ...rest
}) => {
  const [appliedProvider, setAppliedProvider] = useState([])
  const [appliedProviderType, setAppliedProviderType] = useState([])

  const providerList = useFetch({
    request: user.sharedSegment ? api.segment.getProviderList : null,
  })

  const onProviderChange = (data, content) =>
    content === 'provider'
      ? setAppliedProvider(data)
      : setAppliedProviderType(data)

  if (providerList.loading) {
    return <CommonSpinner />
  }

  return (
    <AudienceModalComponent
      {...rest}
      activeParentProvider={activeParentProvider}
      allData={allData}
      providers={providers}
      user={user}
      allProviders={providerList.data}
      appliedProvider={appliedProvider}
      appliedProviderType={appliedProviderType}
      onProviderChange={onProviderChange}
      pmpAvails={pmpAvails}
    />
  )
}

const AudienceModalComponent = ({
  onDragEnd,
  allData,
  activeParentProvider,
  providers,
  user,
  onRemoveSegment,
  setAllData,
  appliedProvider,
  appliedProviderType,
  allProviders,
  onProviderChange,
  pmpAvails,
  formDisable,
}) => {
  let initialPagination = {
    p: 0, // page-number
    s: 100, // page-size
  }

  const [providerFilterCategory, setProviderFilterCategory] = useState('all')
  const initialParams = { ...initialPagination, q: '' }
  const [params, setParams] = useState(initialParams)
  const [loading, setLoading] = useState({
    loader: true,
    bottomLoader: false,
    rightLoader: true,
  })
  const [activeTab, setActiveTab] = useState(
    allProviders?.some((e) => e.owned)
      ? 'owned'
      : user.sharedSegment === false
        ? 'uploaded'
        : 'shared'
  )
  const [selectedProvider, setSelectedProvider] = useState(
    allProviders !== null && [allProviders[0]?.key]
  )
  const [selectedProviderType, setSelectedProviderType] = useState(['ALL'])
  const [popoverVisibleProvider, setPopoverVisibleProvider] = useState(false)
  const [popoverVisibleType, setPopoverVisibleType] = useState(false)
  const [selectedProviderTypeListData, setSelectedProviderTypeListData] =
    useState([])
  const [selectedTag, setSelectedTag] = useState(
    allProviders !== null && [allProviders[0]?.key]
  )
  const [selectedTagType, setSelectedTagType] = useState(['ALL'])
  const [searchValue, setSearchValue] = useState(null)

  const selectedProviderListData = allProviders
    ?.filter((providerItem) => providerItem.key !== 'ALL')
    .map((providerItem) => providerItem.key)

  const typeList = useFetch({
    request: api.mediaPlan.getTypeList,
    payload: {
      params: { type: activeTab === 'uploaded' ? 'upload' : activeTab },
    },
  })

  const setPayloadData = (activeTab, appliedProvider, providers) => {
    const localProviders = isNotEmptyArray(appliedProvider)
      ? appliedProvider
      : selectedProviderListData

    // THIS is FNG DISGUSTING but its how I have to work through all the web of filtering already taking place
    return providers
      .filter((prov) => {
        if (activeTab === 'owned') {
          return prov.owned && localProviders.includes(prov.key)
        }

        if (activeTab === 'shared') {
          return !prov.owned && localProviders.includes(prov.key)
        }

        return false
      })
      .map((pv) => pv.key)
  }

  const setFilterData = (activeTab, appliedProviderType) => {
    let isPresent = appliedProviderType.some(
      (items) => items === 'BID_REQUEST' || items === 'IRIS_ID'
    )
    if (isNotEmptyArray(appliedProviderType)) {
      if (
        activeTab === 'uploaded' &&
        appliedProviderType.includes('BID_REQUEST') &&
        appliedProviderType.includes('IRIS_ID') &&
        appliedProviderType.length < 3
      ) {
        return typeList?.data?.data
          .filter(
            (item) =>
              item.key !== 'ALL' &&
              item.key !== 'BID_REQUEST' &&
              item.key !== 'IRIS_ID'
          )
          .map((items) => items.key)
      } else if (
        activeTab === 'uploaded' &&
        (appliedProviderType.includes('BID_REQUEST') ||
          appliedProviderType.includes('IRIS_ID')) &&
        appliedProviderType.length < 2
      ) {
        return typeList?.data?.data
          .filter(
            (item) =>
              item.key !== 'ALL' &&
              item.key !== 'BID_REQUEST' &&
              item.key !== 'IRIS_ID'
          )
          .map((items) => items.key)
      }
      return activeTab === 'uploaded' && isPresent
        ? appliedProviderType.filter(
            (items) => items !== 'BID_REQUEST' && items !== 'IRIS_ID'
          )
        : appliedProviderType
    } else {
      return typeList?.data?.data
        .filter((item) => item.key !== 'ALL')
        .map((items) => items.key)
    }
  }

  useEffect(() => {
    if (isNotEmptyArray(typeList.data?.data)) {
      setSelectedProviderTypeListData(
        typeList.data?.data
          ?.filter(
            (providerItem) => providerItem.key !== 'ALL' && !providerItem.owned
          )
          .map((providerItem) => providerItem.key)
      )
    }
  }, [typeList.data?.data])

  const onTabChange = (key) => {
    typeList.data.data = []
    setActiveTab(key)
    if (activeTab !== key) {
      Object.keys(allData).forEach((item) => {
        if (item === 'owned' || item === 'uploaded' || item === 'shared') {
          allData[item] = []
        }
      })
    }
    if (params.q || activeTab !== key) {
      const newParams = { ...params, ...initialPagination }
      const allDataObject = { ...allData }
      setParams(newParams)
      setAllData(allDataObject)
      setLoading({ ...loading, loader: true })
    }
    onProviderChange([], 'provider')
    onPopoverCloseProvider()
    onPopoverCloseType()
  }

  const payloadData = setPayloadData(activeTab, appliedProvider, allProviders)
  const filterData = setFilterData(activeTab, appliedProviderType)

  const response = useFetch({
    request: isNotEmptyArray(typeList.data?.data)
      ? api.mediaPlan.segmentList
      : null,
    payload: {
      params: {
        ...params,
        q: encodeURIComponent(params.q),
        type: activeTab === 'uploaded' ? 'upload' : activeTab,
        pmp: !!pmpAvails,
      },
      data: payloadData,
      type: filterData,
    },
  })

  const onSearch = (val) => {
    const searchValue = val?.trim()
    if (searchValue !== params.q) {
      const newParams = { ...params, ...initialPagination }
      newParams.q = searchValue
      const allDataObject = { ...allData }
      allDataObject.shared = []
      allDataObject.owned = []
      allDataObject.uploaded = []
      setParams(newParams)
      setAllData(allDataObject)
      setLoading({ ...loading, loader: true })
    }
  }

  const onProviderClick = useCallback(
    (provider, content) => {
      let selected =
        content === 'provider'
          ? [...selectedProvider]
          : [...selectedProviderType]
      if (selected.includes(provider)) {
        selected.splice(
          selected.findIndex((item) => item === provider),
          1
        )
      } else {
        selected = [...selected, provider]
      }
      selected = selected.filter((providerItem) => providerItem !== 'ALL')
      if (content === 'provider') {
        if (
          provider === 'ALL' ||
          !isNotEmptyArray(selected) ||
          !isNotEmptyArray(selectedTag)
        ) {
          setSelectedTag(allProviders !== null && [allProviders[0]?.key])
          setSelectedProvider(['ALL'])
        } else {
          setSelectedProvider(selected)
          setSelectedTag(
            selected?.filter((providerItem) => providerItem !== 'ALL')
          )
        }
      } else {
        if (
          provider === 'ALL' ||
          !isNotEmptyArray(selected) ||
          !isNotEmptyArray(selectedTagType)
        ) {
          setSelectedTagType(['ALL'])
          setSelectedProviderType(['ALL'])
        } else {
          setSelectedProviderType(selected)
          setSelectedTagType(
            selected?.filter((providerItem) => providerItem !== 'ALL')
          )
        }
      }
    },
    [selectedProvider, selectedProviderType]
  )

  const onApplyFilter = (content) => {
    let extraArr =
      content === 'provider'
        ? getUnmatachedElements(appliedProvider, selectedProvider)
        : getUnmatachedElements(appliedProviderType, selectedProviderType)
    let isNotSameArr = !!extraArr.length
    let selectedProviderList =
      content === 'provider'
        ? isNotEmptyArray(selectedProvider) &&
          selectedProvider.find((provider) => provider === 'ALL')
          ? selectedProvider.filter((providerItem) => providerItem !== 'ALL')
          : selectedProvider
        : isNotEmptyArray(selectedProviderType) &&
            selectedProviderType.find((provider) => provider === 'ALL')
          ? selectedProviderType.filter(
              (providerItem) => providerItem !== 'ALL'
            )
          : selectedProviderType
    if (content === 'provider') {
      if (
        (!isNotEmptyArray(appliedProvider) &&
          JSON.stringify(selectedProviderListData) ===
            JSON.stringify(selectedProviderList)) ||
        (!isNotEmptyArray(selectedProviderList) &&
          JSON.stringify(selectedProviderListData) ===
            JSON.stringify(appliedProvider))
      ) {
        appliedProvider = [...selectedProviderList]
      }
    } else {
      if (
        (!isNotEmptyArray(appliedProviderType) &&
          JSON.stringify(selectedProviderTypeListData) ===
            JSON.stringify(selectedProviderList)) ||
        (!isNotEmptyArray(selectedProviderList) &&
          JSON.stringify(selectedProviderTypeListData) ===
            JSON.stringify(appliedProviderType))
      ) {
        appliedProviderType = [...selectedProviderList]
      }
    }
    let newParams = {}
    if (content === 'provider') {
      if (
        isNotSameArr &&
        JSON.stringify(appliedProvider) !== JSON.stringify(selectedProviderList)
      ) {
        newParams = { ...params, ...initialPagination }
        setParams(newParams)
        //setLoading({ ...loading, loader: true })
        const allDataObject = { ...allData }
        allDataObject.shared = []
        setAllData(allDataObject)
        onProviderChange(selectedProviderList, content)
        appliedProvider = [...selectedProviderList]
      }
    } else {
      if (
        isNotSameArr &&
        JSON.stringify(appliedProviderType) !==
          JSON.stringify(selectedProviderList)
      ) {
        newParams = { ...params, ...initialPagination }
        setParams(newParams)
        //setLoading({ ...loading, loader: true })
        const allDataObject = { ...allData }
        allDataObject.owned = []
        allDataObject.shared = []
        allDataObject.uploaded = []
        setAllData(allDataObject)
        onProviderChange(selectedProviderList, content)
        appliedProviderType = [...selectedProviderList]
      }
    }
    if (content === 'provider') {
      onPopoverCloseProvider()
    } else {
      onPopoverCloseType()
    }
  }

  const onPopoverCloseProvider = () => {
    setSelectedTag(appliedProvider)
    // setPopoverVisible(prevState => ({ ...prevState, [prevState.type]: false }));
    setPopoverVisibleProvider(false)
  }

  const onPopoverCloseType = () => {
    setSelectedTagType(appliedProviderType)
    // setPopoverVisible(prevSate => !prevSate.type);
    setPopoverVisibleType(false)
  }

  let onScroll = (e) => {
    let bottom =
      e.target.scrollHeight - (e.target.clientHeight + e.target.scrollTop)
    if (
      bottom < 20 &&
      !response.loading &&
      !loading.bottomLoader &&
      response.data &&
      response.data.hm
    ) {
      const { p } = params
      setLoading({ ...loading, bottomLoader: true })
      setParams({ ...params, p: p + 1 })
      //getSegmentList({ ...params, p: p + 1 }, activeTab)
    }
  }

  const isTagDisabled = (provider) => {
    const { parentProvider, value } = provider

    if (!activeParentProvider || value.toLowerCase() === 'all') {
      return false
    }

    if (
      parentProvider &&
      parentProvider.toLowerCase() !== activeParentProvider.toLowerCase()
    ) {
      return true
    }

    if (
      !parentProvider &&
      value.toLowerCase() !== activeParentProvider.toLowerCase()
    ) {
      return true
    }

    return false
  }

  const popoverContent = (content, allProviders) => (
    <>
      <Flex gap={2} flexWrap='wrap'>
        {content === 'provider'
          ? allProviders.map((provider) => (
              <Tag.CheckableTag
                disabled={isTagDisabled(provider)}
                key={provider.key}
                checked={selectedTag.includes(provider.key)}
                onClick={() => {
                  if (isTagDisabled(provider)) {
                    return false
                  }
                  onProviderClick(provider.key, 'provider')
                }}
              >
                {provider.value}
              </Tag.CheckableTag>
            ))
          : typeList.data?.data
              ?.filter((provider) => !provider.owned)
              .map((provider) => (
                <Tag.CheckableTag
                  key={provider.key}
                  checked={selectedTagType.includes(provider.key)}
                  onClick={() => onProviderClick(provider.key, 'type')}
                >
                  {provider.value}
                </Tag.CheckableTag>
              ))}
      </Flex>
      <Flex mt={2} pt={2} gap={2} flexWrap='wrap' borderTop='split'>
        <Button
          type='primary'
          size='small'
          onClick={() => onApplyFilter(content)}
        >
          Apply
        </Button>
        <Button
          size='small'
          onClick={
            content === 'provider' ? onPopoverCloseProvider : onPopoverCloseType
          }
        >
          Close
        </Button>
      </Flex>
    </>
  )

  useEffect(() => {
    if (response.data) {
      let allDataObject = { ...allData }
      if (activeTab === 'owned') {
        allDataObject.owned =
          isNotEmptyArray(allDataObject.owned) &&
          JSON.stringify(allDataObject.owned) !==
            JSON.stringify(response.data.segmentList) &&
          params.p > 0
            ? [...allDataObject.owned, ...response.data.segmentList]
            : response.data.segmentList || []
      } else if (activeTab === 'uploaded') {
        allDataObject.uploaded =
          isNotEmptyArray(allDataObject.uploaded) &&
          JSON.stringify(allDataObject.uploaded) !==
            JSON.stringify(response.data.segmentList) &&
          params.p > 0
            ? [...allDataObject.uploaded, ...response.data.segmentList]
            : response.data.segmentList || []
      } else {
        allDataObject.shared =
          isNotEmptyArray(allDataObject.shared) &&
          JSON.stringify(allDataObject.shared) !==
            JSON.stringify(response.data.segmentList) &&
          params.p > 0
            ? [...allDataObject.shared, ...response.data.segmentList]
            : response.data.segmentList || []
      }
      if (isNotEmptyArray(allDataObject.include)) {
        const includedSegments = allDataObject.include.map((segmentItem) => {
          let selectedItem = segmentItem
          if (segmentItem.source === 0) {
            let selectedIndex = allDataObject.shared.findIndex(
              (item) => item.id == segmentItem.id
            )
            if (selectedIndex >= 0) {
              allDataObject.shared[selectedIndex].selected = true
              selectedItem = allDataObject.shared[selectedIndex]
            }

            selectedIndex = allDataObject.owned.findIndex(
              (item) => item.id == segmentItem.id
            )
            if (selectedIndex >= 0) {
              allDataObject.owned[selectedIndex].selected = true
              if (segmentItem?.owned) {
                allDataObject.owned[selectedIndex].owned = true
              }
              selectedItem = allDataObject.owned[selectedIndex]
            }
          }
          if (segmentItem.source === 1) {
            let selectedIndex = allDataObject.uploaded.findIndex(
              (item) => item.id == segmentItem.id
            )
            if (selectedIndex >= 0) {
              allDataObject.uploaded[selectedIndex].selected = true
              selectedItem = allDataObject.uploaded[selectedIndex]
            }
          }
          return selectedItem
        })
        allDataObject.include = includedSegments
      }
      if (isNotEmptyArray(allDataObject.exclude)) {
        const excludeSegments = allDataObject.exclude.map((segmentItem) => {
          let selectedItem = segmentItem
          if (segmentItem.source === 0) {
            let selectedIndex = allDataObject.shared.findIndex(
              (item) => item.id == segmentItem.id
            )
            if (selectedIndex >= 0) {
              allDataObject.shared[selectedIndex].selected = true
              selectedItem = allDataObject.shared[selectedIndex]
            }

            selectedIndex = allDataObject.owned.findIndex(
              (item) => item.id == segmentItem.id
            )
            if (selectedIndex >= 0) {
              allDataObject.owned[selectedIndex].selected = true
              if (segmentItem?.owned) {
                allDataObject.owned[selectedIndex].owned = true
              }
              selectedItem = allDataObject.owned[selectedIndex]
            }
          }
          if (segmentItem.source === 1) {
            let selectedIndex = allDataObject.uploaded.findIndex(
              (item) => item.id == segmentItem.id
            )
            if (selectedIndex >= 0) {
              allDataObject.uploaded[selectedIndex].selected = true
              selectedItem = allDataObject.uploaded[selectedIndex]
            }
          }
          return selectedItem
        })
        allDataObject.exclude = excludeSegments
      }
      setAllData(allDataObject)
      setLoading({ bottomLoader: false, loader: false, rightLoader: false })
    }
  }, [response.data])

  const openPopover = (content) => {
    if (content === 'provider') {
      let defaultSelectedTag = selectedTag[0]
      if (isNotEmptyArray(appliedProvider) && defaultSelectedTag !== 'ALL') {
        setSelectedProvider(appliedProvider)
      } else {
        setSelectedTag(allProviders !== null && [allProviders[0]?.key])
        setSelectedProvider(allProviders !== null && [allProviders[0]?.key])
      }
      setPopoverVisibleType(false)
      setPopoverVisibleProvider(true)
    } else {
      let defaultSelectedTag = selectedTagType[0]
      if (
        isNotEmptyArray(appliedProviderType) &&
        defaultSelectedTag !== 'ALL'
      ) {
        if (
          activeTab === 'uploaded' &&
          (appliedProviderType.includes('IRIS_ID') ||
            appliedProviderType.includes('BID_REQUEST')) &&
          appliedProviderType.length < 2
        ) {
          setSelectedProviderType(['ALL'])
        } else if (
          activeTab === 'uploaded' &&
          appliedProviderType.includes('IRIS_ID') &&
          appliedProviderType.includes('BID_REQUEST') &&
          appliedProviderType.length < 3
        ) {
          setSelectedProviderType(['ALL'])
        } else {
          setSelectedProviderType(appliedProviderType)
        }
      } else {
        setSelectedTagType(['ALL'])
        setSelectedProviderType(['ALL'])
      }
      setPopoverVisibleProvider(false)
      setPopoverVisibleType(true)
    }
  }

  const showSharedTab = () =>
    allProviders.some((e) => !e.owned && e.key !== 'ALL')

  const tabList = []

  if (allProviders?.some((e) => e.owned)) {
    tabList.push({ key: 'owned', tab: 'Owned' })
  }

  if (!pmpAvails && user.uploadedSegment) {
    tabList.push({ key: 'uploaded', tab: 'Uploaded' })
  }

  if (showSharedTab()) {
    tabList.push({ key: 'shared', tab: 'Shared' })
  }

  const filteredProviders = allProviders
    .filter((provider) => {
      if (providerFilterCategory.toLowerCase() === 'all') {
        return true
      }

      return (
        providerFilterCategory.toLowerCase() ===
        provider.parentProvider?.toLowerCase()
      )
    })
    .filter((provider) => {
      if (provider.key === 'ALL') {
        return true
      }

      if (activeTab === 'owned') {
        return provider.owned
      }

      if (activeTab === 'shared') {
        return !provider.owned
      }

      return false
    })

  useEffect(() => {
    setSelectedTag(allProviders !== null && [allProviders[0]?.key])
  }, [activeTab])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid gap={3} columns={[1, 2]} height={['auto', '100%']}>
        <Box rowSpan={2}>
          <Card
            title='Available Segments'
            size='small'
            tabPlacement='right'
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={onTabChange}
            width='100%'
            height='100%'
          >
            <Flex mb={3} gap={2} justifyContent='space-between'>
              <Box flexGrow={1}>
                <Input.Search
                  value={searchValue}
                  placeholder='Search Segments'
                  onChange={(e) => setSearchValue(e.target.value)}
                  onSearch={(value) => onSearch(value)}
                  allowClear
                />
              </Box>
              {allProviders !== null ? (
                <Box flex='none'>
                  <PopoverFilter
                    filter='provider'
                    title={
                      <ProviderFilterTitle
                        providerFilterCategory={providerFilterCategory}
                        setProviderFilterCategory={setProviderFilterCategory}
                        filteredProviders={filteredProviders}
                      />
                    }
                    visible={popoverVisibleProvider}
                    popoverContent={popoverContent}
                    openPopover={openPopover}
                    appliedProvider={appliedProvider}
                    appliedProviderType={appliedProviderType}
                    allProviders={filteredProviders}
                    activeTab={activeTab}
                  />
                </Box>
              ) : null}
              <Box flex='none'>
                <PopoverFilter
                  filter='type'
                  title='Filter By Type'
                  visible={popoverVisibleType}
                  popoverContent={popoverContent}
                  openPopover={openPopover}
                  appliedProvider={appliedProvider}
                  appliedProviderType={appliedProviderType}
                  activeTab={activeTab}
                />
              </Box>
            </Flex>
            {activeTab === 'uploaded' ? (
              <SegmentListing
                activeParentProvider={activeParentProvider}
                providers={providers}
                response={response}
                activeTab={activeTab}
                type='uploaded'
                listData={allData.uploaded}
                loading={response.loading}
                onScroll={onScroll}
              />
            ) : null}
            {activeTab === 'owned' ? (
              <SegmentListing
                activeParentProvider={activeParentProvider}
                providers={providers}
                response={response}
                activeTab={activeTab}
                type='owned'
                listData={allData.owned}
                loading={response.loading}
                onScroll={onScroll}
              />
            ) : null}
            {activeTab === 'shared' ? (
              <SegmentListing
                activeParentProvider={activeParentProvider}
                providers={providers}
                response={response}
                activeTab={activeTab}
                type='shared'
                listData={allData.shared}
                loading={response.loading}
                onScroll={onScroll}
              />
            ) : null}
          </Card>
        </Box>
        <Box>
          <Card
            size='small'
            title='Included Segments'
            width='100%'
            height='100%'
            overflow='auto'
            extra={
              !pmpAvails ? (
                <AndOrRadio
                  name='includeSegmentAnd'
                  disabled={formDisable || !isNotEmptyArray(allData.include)}
                />
              ) : null
            }
          >
            <SegmentDropSection
              type='include'
              listData={allData.include}
              onRemove={onRemoveSegment}
            />
          </Card>
        </Box>
        <Box>
          <Card
            size='small'
            title='Excluded Segments'
            width='100%'
            height='100%'
            overflow='auto'
          >
            <SegmentDropSection
              type='exclude'
              listData={allData.exclude}
              onRemove={onRemoveSegment}
            />
          </Card>
        </Box>
      </Grid>
    </DragDropContext>
  )
}

const AndOrRadio = ({ name, disabled }) => {
  return (
    <Field name={name} mb={0}>
      <Radio.Group name={name} disabled={disabled}>
        <Radio key='OR' value='OR' style={{ margin: 0 }}>
          Or
        </Radio>
        <Radio key='AND' value='AND' style={{ margin: 0 }}>
          And
        </Radio>
      </Radio.Group>
    </Field>
  )
}

const getSelectedFilterCount = (
  appliedProvider,
  appliedProviderType,
  filter,
  activeTab
) => {
  if (filter === 'provider') {
    return appliedProvider?.find((p) => p !== 'ALL')
      ? appliedProvider.length
      : 'All'
  }

  if (activeTab === 'uploaded') {
    const isPresent = appliedProviderType.some(
      (t) => t === 'BID_REQUEST' || t === 'IRIS_ID'
    )
    const appliedData = appliedProviderType.filter(
      (t) => t !== 'BID_REQUEST' && t !== 'IRIS_ID'
    )
    if (isPresent && appliedProviderType.length <= 2 && !appliedData.length) {
      return 'All'
    }
    return appliedProviderType?.find((p) => p !== 'ALL')
      ? appliedData.length
      : 'All'
  }

  return appliedProviderType?.find((p) => p !== 'ALL')
    ? appliedProviderType.length
    : 'All'
}

const PopoverFilter = ({
  filter,
  title,
  visible,
  popoverContent,
  openPopover,
  appliedProvider,
  allProviders,
  appliedProviderType,
  activeTab,
}) => {
  return (
    <Popover
      visible={visible}
      title={title}
      content={popoverContent(filter, allProviders)}
      placement='bottomLeft'
      trigger='click'
      overlayStyle={{ width: '500px' }}
    >
      <Button icon={<FilterFilled />} onClick={() => openPopover(filter)}>
        {filter.charAt(0).toUpperCase() + filter.slice(1)}:{' '}
        {getSelectedFilterCount(
          appliedProvider,
          appliedProviderType,
          filter,
          activeTab
        )}
      </Button>
    </Popover>
  )
}

const SegmentDropSection = ({ type, listData, onRemove, loading }) => {
  const getItemStyle = (draggableStyle) => ({
    outline: 'none',
    userSelect: 'none',
    ...draggableStyle,
  })

  if (loading) {
    return (
      <Box height='calc(100% - 50px)'>
        <CommonSpinner />
      </Box>
    )
  }

  return (
    <Droppable droppableId={type}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          height='216px'
          overflowX='hidden'
          overflowY='auto'
        >
          {isNotEmptyArray(listData) ? (
            listData.map((item, index) => (
              <Draggable
                key={`id${item.id}`}
                draggableId={`id${item.id}`}
                isDragDisabled={true}
                index={index}
              >
                {(provided) => (
                  <SegmentItem
                    innerRef={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    mb={2}
                    title={item.title}
                    price={item.price}
                    showPrice={item.source === 0 ? !item.owned : null}
                    dataType={item.dataType}
                    provider={item.provider}
                    dailyAvails={item.dailyAvails}
                    users={item.users}
                    source={item.source}
                    owned={item.owned}
                    allowRemove={true}
                    onRemove={() => onRemove(type, item)}
                    style={getItemStyle(provided.draggableProps.style)}
                  />
                )}
              </Draggable>
            ))
          ) : (
            <Flex
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='100%'
              color='primary.base'
            >
              <Box>
                <Box fontSize={7}>
                  <DragDropOutlined />
                </Box>
                <Paragraph m={0}>Drag and Drop Segment</Paragraph>
                <Paragraph m={0}>
                  to <span style={{ textTransform: 'capitalize' }}>{type}</span>
                </Paragraph>
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </Droppable>
  )
}

const SegmentListing = ({
  activeParentProvider,
  type,
  providers,
  listData,
  loading,
  onScroll,
}) => {
  const getItemStyle = (draggableStyle) => ({
    outline: 'none',
    userSelect: 'none',
    ...draggableStyle,
  })

  if (loading) {
    return (
      <Box height='464px'>
        <CommonSpinner />
      </Box>
    )
  }

  return (
    <Droppable droppableId={type}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          onScroll={onScroll}
          height='464px'
          overflow='auto'
        >
          {isNotEmptyArray(listData)
            ? listData.map((item, index) => {
                const parentProvider = getParentProvider(item, providers)
                const isDisabled =
                  activeParentProvider &&
                  activeParentProvider.toLowerCase() !==
                    parentProvider?.toLowerCase()
                return (
                  <Draggable
                    key={`id${type}${item.id}`}
                    draggableId={`id${type}${item.id}`}
                    isDragDisabled={item.selected || isDisabled}
                    index={index}
                  >
                    {(provided) => (
                      <SegmentItem
                        disabled={isDisabled}
                        innerRef={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        mb={2}
                        title={item.title}
                        price={item.price}
                        showPrice={type === 'shared'}
                        dataType={item.dataType}
                        provider={item.provider}
                        dailyAvails={item.dailyAvails}
                        users={item.users}
                        selected={item.selected}
                        style={getItemStyle(provided.draggableProps.style)}
                      />
                    )}
                  </Draggable>
                )
              })
            : null}
          {loading.bottomLoader ? (
            <Box
              width='100%'
              position='absolute'
              bottom={0}
              left={0}
              py={2}
              bg='bg.component'
            >
              <Spinner size='small' />
            </Box>
          ) : null}
          {!isNotEmptyArray(listData) ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='No Segments Found'
            />
          ) : null}
        </Box>
      )}
    </Droppable>
  )
}

const SegmentItem = ({
  disabled,
  innerRef,
  title,
  price,
  showPrice,
  dataType,
  provider,
  dailyAvails,
  users,
  source,
  owned,
  selected,
  allowRemove,
  onRemove,
  ...rest
}) => {
  const cpm = price ? Math.round(price * 100) / 100 : 0

  return (
    <Flex
      ref={innerRef}
      p={2}
      gap={2}
      flexDirection={['column', 'column', 'row']}
      justifyContent='space-between'
      bg='gray.1'
      border='base'
      borderRadius='base'
      cursor={selected || disabled ? 'not-allowed' : 'grab'}
      opacity={selected || disabled ? 0.5 : 1}
      {...rest}
    >
      <Box fontSize={1} color='primary.base' title={title}>
        {title}
      </Box>
      <Flex gap={2} justifyContent='flex-end' flex='none'>
        <Flex gap={1} flexDirection='column' justifyContent='flex-start'>
          <Flex gap={1} justifyContent='flex-end'>
            {provider ? <Tag fontWeight='bold'>{provider}</Tag> : null}
            {dataType ? <Tag>{dataType}</Tag> : null}
            {isNumber(source) ? (
              <Tag color={source === 0 ? 'blue' : 'magenta'}>
                {source === 0 ? <>{owned ? 'Owned' : 'Shared'}</> : null}
                {source === 1 ? 'Uploaded' : null}
              </Tag>
            ) : null}
          </Flex>
          <Flex gap={1} justifyContent='flex-end'>
            <Tooltip title='Avails' placement='bottom'>
              <Tag>
                <TrafficOutlined />
                {dailyAvails === null ? (
                  <Skeleton width={25} />
                ) : (
                  numberFormatter(dailyAvails ?? 0)
                )}
              </Tag>
            </Tooltip>
            <Tooltip title='Users' placement='bottom'>
              <Tag>
                <UserOutlined />
                {dataType === SEGMENT_TYPE.CONTEXTUAL.key
                  ? 'NA'
                  : numberFormatter(users ?? 0)}
              </Tag>
            </Tooltip>
            {showPrice && isNumber(price) ? (
              <Tag color='green'>{`${formatCurrency(cpm)} CPM`}</Tag>
            ) : null}
          </Flex>
        </Flex>
        {allowRemove ? (
          <Box my='auto'>
            <Button
              danger
              type='link'
              size='small'
              icon={<CloseOutlined />}
              onClick={onRemove}
            />
          </Box>
        ) : null}
      </Flex>
    </Flex>
  )
}

function getUnmatachedElements(first = [], second = []) {
  const firstArr = second.length > first.length ? [...second] : [...first]
  const secondArr = second.length > first.length ? [...first] : [...second]
  return firstArr.filter((val) => !secondArr.includes(val))
}

AudienceModal.propTypes = {
  values: PropTypes.object,
  id: PropTypes.string,
  dspDeal: PropTypes.bool,
  pmpAvails: PropTypes.bool,
  adminCreate: PropTypes.bool,
  showModal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onSuccessCallback: PropTypes.func,
}

export default AudienceModal
