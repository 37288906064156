import React from 'react'
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom'
import {
  Backdrop,
  Box,
  Button,
  BrandCard,
  Divider,
  Flex,
  Text,
  useToast,
} from '@beachfront/ui'
import { MailFilled, EditOutlined } from '@beachfront/ui/icons'
import { Form, FORM_ERROR, SubmitError, Field } from '@beachfront/ui/forms'
import OtpInput from 'react18-input-otp'

import logo from '../../assets/bf-logo-horizontal-white.png'
import { resolveProp, getErrorMessage } from '../../utils'
import { authService } from '../../context'

import style from './verify-otp.module.scss'
import { otpValidate } from './@utils'

const VerifyOtp = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()

  if (!location.state?.email) {
    return <Navigate to='/verify-email' />
  }

  let initialValues = {
    username: location.state?.email,
    transactionId: location.state?.transactionId,
    otpLength: location.state?.otpLength,
  }

  let onSubmit = (values) => {
    return authService
      .verifyOtp({
        data: {
          otp: values.otp,
          transactionId: location.state?.transactionId,
        },
      })
      .then(
        (response) => {
          let pathname = resolveProp(
            location,
            'state.referrer.pathname',
            '/reset-password'
          )
          navigate(pathname, {
            state: {
              email: location.state?.email,
              transactionId: response.data.transactionId,
            },
          })
        },
        (error) => {
          return { [FORM_ERROR]: getErrorMessage(error) }
        }
      )
  }

  function resendOtp(transactionId, form) {
    return authService
      .resendOtp({
        data: {
          transactionId: transactionId,
        },
      })
      .then(
        (response) => {
          if (!response?.data?.success) {
            toast.success({
              title: response?.data?.msg || 'OTP has been resent to your email',
            })
            form.change('transactionId', response.data.transactionId)
            form.change('otpLength', response.data.otpLength)
          }
        },
        (error) => {
          return { [FORM_ERROR]: getErrorMessage(error) }
        }
      )
  }

  return (
    <Backdrop preset='prism'>
      <Box width={450}>
        <Form
          initialValues={initialValues}
          validate={otpValidate}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, submitting, values, form }) => (
            <form onSubmit={handleSubmit}>
              <BrandCard
                logo={logo}
                fontSize={3}
                title='Verify OTP'
                mode='dark'
              >
                <SubmitError mb={3} />
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  mb={3}
                  fontSize={2}
                >
                  <MailFilled />
                  <Text mx={2}>{location.state?.email}</Text>
                  <Link to='/verify-email'>
                    <EditOutlined />
                  </Link>
                </Flex>
                <Box>
                  <Box fontWeight='bold' textAlign='center'>
                    Enter the OTP sent to your email.
                  </Box>
                  <Flex alignItems='center' justifyContent='center' mt={3}>
                    <Field name='otp'>
                      <OtpInput
                        containerStyle={style['container-style']}
                        inputStyle={style['input-style']}
                        separator={<span>&nbsp;&nbsp;</span>}
                        numInputs={location.state?.otpLength}
                      />
                    </Field>
                  </Flex>
                  <Button
                    block
                    type='primary'
                    htmlType='submit'
                    size='large'
                    loading={submitting}
                  >
                    Verify OTP
                  </Button>
                </Box>
                <Divider />
                <Flex alignItems='center' justifyContent='space-between'>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => resendOtp(values.transactionId, form)}
                  >
                    Resend OTP
                  </Button>
                  <Link to='/login'>Back to login</Link>
                </Flex>
              </BrandCard>
            </form>
          )}
        </Form>
      </Box>
      <Box pt={3} color='white'>
        &copy; {new Date().getFullYear()} Beachfront Media LLC
      </Box>
    </Backdrop>
  )
}

export default VerifyOtp
