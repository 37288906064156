import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import createDecorator from 'final-form-focus'

import { api } from '../../../client-api'
import { TIMEZONE } from '../../../enums'
import {
  isNotEmptyArray,
  getErrorMessage,
  isString,
  isNumber,
  isValidMongoId,
} from '../../../utils'
import { PageHeader } from '../../../components'
import { formInitialValues } from '../@constants'
import { validateForm, getKeys, getSegmentKeys } from '../@utils'

import { TargetingForm } from '.'

const focusOnError = createDecorator()

const CreateMediaPlan = () => {
  const [availsDsp, setAvailsDsp] = useState('')
  const navigate = useNavigate()
  const toast = useToast()
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)

  const backUrl = '/mediaplan'

  const onSubmit = (values) => {
    if (values?.title) {
      let targetData = {
        platform: values.platform.includes('ALL') ? ['ALL'] : values.platform,
        country: values.country || [],
        dma: [],
        state: [],
        marketplaceIds: values.marketplaceIds || [],
        excludeCountry: values.excludeCountry === 'EXCLUDE',
        excludeDma: values.excludeDma === 'EXCLUDE',
        excludeState: values.excludeState === 'EXCLUDE',
        excludeiabCat: values.excludeiabCat === 'EXCLUDE',
        excludeNetwork: values.excludeNetwork === 'EXCLUDE',
        excludeChannel: values.excludeChannel === 'EXCLUDE',
        excludeGenres: values.excludeGenres === 'EXCLUDE',
        excludeUserId: values.excludeUserId === 'EXCLUDE',
        excludeRating: values.excludeRating === 'EXCLUDE',
        excludeApp: values.excludeApp === 'EXCLUDE',
        includeLivestream: values.includeLivestream,
      }

      if (isNotEmptyArray(values.country) && values.country.includes('US')) {
        if (isNotEmptyArray(values.dma)) {
          targetData['dma'] = values.dma
        }
        if (isNotEmptyArray(values.state)) {
          targetData['state'] = values.state
        }
      }
      if (isNotEmptyArray(values.genres)) {
        targetData['masterListGenres'] = values.genres.filter(
          (item) => isString(item) && isValidMongoId(item)
        )
        targetData['genres'] = values.genres.filter((item) => isNumber(item))
      }
      if (isNotEmptyArray(values.network)) {
        targetData['network'] = values.network
      }
      if (isNotEmptyArray(values.network)) {
        targetData['channel'] = values.channel
      }
      if (isNotEmptyArray(values.iabCategory)) {
        targetData['iabCategory'] = values.iabCategory
      }
      if (isNotEmptyArray(values.app)) {
        targetData['app'] = values.app
      }
      if (isNotEmptyArray(values.publishers)) {
        targetData['userid'] = values.publishers
      }
      if (isNotEmptyArray(values.rating)) {
        targetData['rating'] = values.rating
      }

      let postData = {
        audienceBooleanLogic: values.audienceBooleanLogic,
        contentBooleanLogic: values.contentBooleanLogic,
        title: values.title,
        status: values.status === 'ENABLE',
        mediaType: isNotEmptyArray(values.mediaType)
          ? values.mediaType[0]
          : null,
        targeting: targetData,
        includeSegments: isNotEmptyArray(values.includeSegments)
          ? getSegmentKeys(values.includeSegments)
          : [],
        excludeSegments: isNotEmptyArray(values.excludeSegments)
          ? getSegmentKeys(values.excludeSegments)
          : [],
        domainMedia:
          values.domainMedia && isNotEmptyArray(values.domainMedia.ids)
            ? {
                ...values.domainMedia,
                ids: getKeys(values.domainMedia.ids, 'id'),
              }
            : null,
        bundleMedia:
          values.bundleMedia && isNotEmptyArray(values.bundleMedia.ids)
            ? {
                ...values.bundleMedia,
                ids: getKeys(values.bundleMedia.ids, 'id'),
              }
            : null,
        zipMedia: null,
        dmaMedia: null,
        includeSegmentAnd: values.includeSegmentAnd === 'AND',
      }

      if (
        isNotEmptyArray(values.country) &&
        values.country.includes('US') &&
        values.zipMedia &&
        isNotEmptyArray(values.zipMedia.ids)
      ) {
        postData.zipMedia = {
          ...values.zipMedia,
          ids: getKeys(values.zipMedia.ids, 'id'),
        }
      }

      if (
        isNotEmptyArray(values.country) &&
        values.country.includes('US') &&
        values.dmaMedia &&
        isNotEmptyArray(values.dmaMedia.ids)
      ) {
        postData.dmaMedia = {
          ...values.dmaMedia,
          ids: getKeys(values.dmaMedia.ids, 'id'),
        }
      }

      if (Object.hasOwn(values, 'buyers')) {
        postData['buyerId'] = values.buyers
      }

      return api.mediaPlan.create(postData).then(
        (res) => {
          if (res.data?.success) {
            toast.success({ title: 'Media plan created.' })
            navigate(backUrl)
          } else {
            toast.error({
              title:
                res.data?.msg ||
                res.data.errorDetails ||
                'Unable to create this media plan. Please try again.',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    } else {
      toast.error({
        title: 'Please fill title in setting tab',
      })
    }
  }

  return (
    <Form
      initialValues={formInitialValues}
      validate={(values) => validateForm({ values })}
      onSubmit={onSubmit}
      decorators={[focusOnError]}
    >
      {({ handleSubmit, values, invalid, dirty, submitting }) => (
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <PageHeader
            title='Create Media Plan'
            actions={
              <>
                <Link to={backUrl}>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  disabled={!dirty}
                  loading={submitting}
                  htmlType='submit'
                  type='primary'
                >
                  Create
                </Button>
              </>
            }
          />
          <Box mt={3}>
            <TargetingForm
              availsDsp={availsDsp}
              values={values}
              create={true}
              publishers={true}
              invalid={invalid}
              timezone={timezone}
              onAvailsDspChange={setAvailsDsp}
              onTimezoneChange={setTimezone}
            />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default CreateMediaPlan
