export const localFilterKey = {
  ACCOUNT_HISTORY: 'account-history',
  ACCOUNT_INVENTORY: 'account-inventory',
  ADMIN_ACCOUNT: 'admin-account-filter',
  ADMIN_DSP: 'admin-dsp-filter',
  ADMIN_META: 'admin-meta-filter',
  ADMIN_USERS: 'admin-user-filter',
  ADMIN_DEAL_LIBRARY: 'admin-deal-library',
  ADMIN_MEDIA_PLANS: 'admin-media-plans',
  APP_INFO: 'app-info',
  BUNDLE_MEDIA_LISTS: 'bundle-media-lists',
  DOMAIN_MEDIA_LISTS: 'domain-media-lists',
  METRO_CODE_MEDIA_LISTS: 'metrocode-media-lists',
  ZIP_CODE_MEDIA_LISTS: 'zipcode-media-lists',
  MEDIA_LIST_ITEMS: 'media-list-items',
  ATTACH_MEDIA_LIST: 'attach-media-list',
  MEDIA_LIST_HISTORY: 'media-list-history',
  DASHBOARD: 'dashboard',
  DASHBOARD_ADMIN: 'dashboard-admin',
  DEAL: 'deal',
  DEAL_ADMIN: 'deal-admin',
  DEAL_FEATURED: 'deal-featured',
  DEAL_LIBRARY: 'deal-library',
  DEAL_LIBRARY_FEATURED: 'deal-library-featured',
  DEAL_DASHBOARD: 'deal-dashboard',
  DEAL_HISTORY: 'deal-history',
  EXISTING_AVAILS: 'existing-avails',
  INSIGHT: 'insight',
  LOGIN_ACCOUNT: 'login-account',
  MEDIA_PLANS: 'media-plans',
  MEDIA_PLAN_HISTORY: 'media-plan-history',
  FFID_REACH: 'ffid-reach',
  TRANSPARENCY: 'transparency',
  SHARED_SEGMENTS: 'shared-segments',
  UPLOADED_SEGMENTS: 'uploaded-segments',
  SEGMENT_HISTORY: 'segment-history',
  SUPPLY_REQUEST: 'supply-request-filter',
  OWNED_REPORTS: 'owned-reports',
  RESTRICTED_PUBLISHERS: 'restricted-publishers',
  SHARED_REPORTS: 'shared-reports',
  USER: 'user-filter',
  USER_HISTORY: 'user-history',
}
