import moment from 'moment'

import { MARGIN_TYPE } from '../../../enums'
import { isArray } from '../../../utils'

export default function (values) {
  let errors = {}

  if (values.title) {
    values.title = values.title.trim()
  }
  if (!values.title) {
    errors.title = 'Required'
  }

  if (!values.dealId) {
    errors.dealId = 'Deal ID is required.'
  }

  if (isArray(values.pubSeats) && values.pubSeats.length > 1) {
    errors.pubSeats = 'Please select one seat per deal.'
  }

  // if (values.dealId && !isValidAlphaNumeric(values.dealId)) {
  //   errors.dealId = 'Deal ID only accept alpha numeric.'
  // }
  if (!values.dealDspId) {
    errors.dealDspId = 'Please select DSP'
  }

  // if (!values.type) {
  //   errors.type = 'Please select deal type'
  // }

  if (values?.rate && Number(values?.rate) === 0) {
    errors.rate = 'Floor CPM should be more than 0.'
  }

  if (!values?.rate) {
    errors.rate = 'Floor CPM is required.'
  }
  if (!values.marginType) {
    errors.marginType = 'Please select margin type'
  }
  if (!values.marginValue && values.marginValue !== 0) {
    errors.marginValue = 'Margin value is required'
  }
  if (
    values.marginType === MARGIN_TYPE.PERCENT.key &&
    values.marginValue > 100
  ) {
    errors.marginValue = 'Percentage must be between 0 and 100.'
  }
  // if (values.budgetType === null) {
  //   errors.budgetType = 'Please choose budget type'
  // }
  // if (values.budgetType === 'DAILY') {
  //   if (values.budgetOptionsDaily === 'BUDGET' && !values.budget) {
  //     errors.budget = 'Budget is required'
  //   }
  // }
  // if (
  //   values.budgetType !== 'OPEN' &&
  //   ((values.budgetType === 'DAILY' &&
  //     values.budgetOptionsDaily === 'IMPRESSION' &&
  //     !values.impression) ||
  //     (values.budgetType !== 'DAILY' &&
  //       values.budgetOptionsOverall &&
  //       !values.impression))
  // ) {
  //   errors.impression = 'Impression is required'
  // }
  // if (values.frequencyType === null) {
  //   errors.frequencyType = 'Please choose frequency capping'
  // }
  // if (values.frequencyType === 'DAILY' && !values.frequency) {
  //   errors.frequency = 'Frequency is required'
  // }
  if (!values.deliveryType) {
    errors.deliveryType = 'Please choose flight date type'
  }
  if (
    (values.deliveryType === 'CUSTOM' || values.budgetType === 'OVERALL') &&
    !values.start
  ) {
    errors.start = 'Please select start date & time'
  }
  if (
    (values.deliveryType === 'CUSTOM' || values.budgetType === 'OVERALL') &&
    !values.end
  ) {
    errors.end = 'Please select end date & time'
  }
  if (
    (values.deliveryType === 'CUSTOM' || values.budgetType === 'OVERALL') &&
    values.start &&
    values.end &&
    moment(values.start).valueOf() > moment(values.end).valueOf()
  ) {
    errors.start =
      'Start date and time should always be less than the end date and time.'
  }

  if (
    values.dayPartingType === 'CUSTOM' &&
    values.dayPartingSchedules?.length < 1
  ) {
    errors.dayPartingSchedules = 'Required when Choose Times is active'
  }

  return errors
}
