import React, { useState, useMemo } from 'react'
import { Box, Flex, Grid, Card, Tabs, Heading, Button } from '@beachfront/ui'

import { useUserData, useFilters } from '../../hooks'
import { DATE_RANGES, ACCOUNT_TYPE, MEDIA_TYPE } from '../../enums'
import { localFilterKey } from '../../components/filter'
import { PageHeader } from '../../components'

import {
  TransparencyFilter,
  SeatsTable,
  AdomainsTable,
  SupplyTable,
  OverallTakeRate,
  TopSpend,
} from './@components'

const { TabPane } = Tabs

const Transparency = () => {
  const [expanded, setExpanded] = useState({ seats: false, adomain: false })
  const [activeSupply, setActiveSupply] = useState(true)
  const [user] = useUserData()
  const accountType = user?.accountType?.key

  const [filters, setFilters] = useFilters(localFilterKey.TRANSPARENCY, {
    initialState: [
      { dataIndex: 'date', operator: 'eq', value: DATE_RANGES['Today'] },
    ],
    schema: { date: 'date' },
  })

  const requestPayload = useMemo(
    () => getRequestPayload(filters.populated),
    [filters.populated]
  )

  const toggleSeat = () => {
    setExpanded((prev) => ({
      seats: !prev.seats,
      adomain: false,
    }))
  }

  const toggleAdomain = () => {
    setExpanded((prev) => ({
      adomain: !prev.adomain,
      seats: false,
    }))
  }

  return (
    <>
      <PageHeader title='Transparency'>
        <Box mt={3}>
          <TransparencyFilter filters={filters.all} setFilters={setFilters} />
        </Box>
      </PageHeader>
      <Grid mt={2} gap={3}>
        <Box columnSpan={expanded.seats || expanded.adomain ? '24' : '12'}>
          <Card size='small' hidden={expanded.adomain}>
            <SeatsTable
              requestPayload={requestPayload}
              accountType={accountType}
              expanded={expanded.seats}
              onExpandChange={toggleSeat}
            />
          </Card>
          {accountType !== ACCOUNT_TYPE.BRAND.key ? (
            <Card mt={3} size='small' hidden={expanded.seats}>
              <AdomainsTable
                requestPayload={requestPayload}
                accountType={accountType}
                expanded={expanded.adomain}
                onExpandChange={toggleAdomain}
              />
            </Card>
          ) : null}
        </Box>
        <Box columnSpan={12} hidden={expanded.seats || expanded.adomain}>
          <Card minHeight={634} size='small'>
            <Tabs>
              <TabPane key='spend' tab='Top Spend'>
                <TopSpend requestPayload={requestPayload} />
              </TabPane>
              <TabPane key='overall' tab='Overall Take Rate'>
                <OverallTakeRate requestPayload={requestPayload} />
              </TabPane>
            </Tabs>
          </Card>
        </Box>
      </Grid>
      {accountType !== ACCOUNT_TYPE.BRAND.key ? (
        <Box hidden={expanded.seats || expanded.adomain}>
          <Flex my={3} gap={2} alignItems='center'>
            <Heading m={0} level={3}>
              {activeSupply ? 'Active' : 'Inactive'} Supply
            </Heading>
            <Button
              type='link'
              onClick={() => setActiveSupply((prev) => !prev)}
            >
              View {activeSupply ? 'Inactive' : 'Active'} Supply
            </Button>
          </Flex>
          <SupplyTable requestPayload={requestPayload} active={activeSupply} />
        </Box>
      ) : null}
    </>
  )
}

const getRequestPayload = (filters) => {
  const dateRange = filters.find((f) => f.dataIndex === 'date')?.value
  const mediaType = filters.find((f) => f.dataIndex === 'mediaType')?.value
  const platform = filters.find((f) => f.dataIndex === 'platform')?.value

  const formatMediaType = (type) => {
    if (type === MEDIA_TYPE.VIDEO.key) {
      return '0'
    }
    if (type === MEDIA_TYPE.DISPLAY.key) {
      return '1'
    }
    return type
  }

  return {
    dateRange: {
      range: 'custom',
      startDate: dateRange?.[0].format('YYYY-MM-DD'),
      endDate: dateRange?.[1].format('YYYY-MM-DD'),
    },
    mediaType: formatMediaType(mediaType) ?? '',
    platform: platform ?? '',
  }
}

export default Transparency
