import React, { useMemo, useState } from 'react'
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom'
import { Box, Button, Card, Grid, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import moment from 'moment'

import { api } from '../../../client-api'
import { MARGIN_TYPE } from '../../../enums'
import {
  isNotEmptyObject,
  getErrorMessage,
  formatSchedule,
  isNotEmptyArray,
  makeArray,
} from '../../../utils'
import { useFetch, useUserData } from '../../../hooks'
import { PageHeader, CommonSpinner, DirtyPrompt } from '../../../components'
import FourOhFour from '../../four-oh-four'
import { createDealTabs, formInitialValues, requiredField } from '../@constants'
import { validateForm } from '../@utils'

import { SettingForm, DeliveryForm, ConfigurationForm } from '.'

const CreateDeal = () => {
  const { type, id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const toast = useToast()
  const [user] = useUserData()
  const [created, setCreated] = useState(true)
  const [selectedDsp, setSelectedDsp] = useState({})
  const [loading, setLoading] = useState(false)

  const baseLocation = pathname.includes('template')
    ? `/deals/self/template/${id}`
    : '/deals/self/create'
  const backLocation = pathname.includes('template')
    ? '/deals/library'
    : '/deals/self'

  let response = useFetch({
    request: id ? api.deal.library.admin.getDealById : null,
    payload: { id },
  })

  let initial = useMemo(() => {
    let data = {
      ...formInitialValues,
      marginType: MARGIN_TYPE.FLAT.key,
      marginValue: 0,
    }

    if (response.data && id) {
      const { title, cpm } = response.data
      data = {
        ...data,
        title,
        marginType: MARGIN_TYPE.PERCENT.key,
        rate: cpm.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      }
    }
    return data
  }, [response.data, formInitialValues])

  const onSubmit = (values, form, invalid, errors) => {
    let errorFieldName = ''
    let errorField = ''

    if (isNotEmptyObject(errors)) {
      errorField = Object.keys(errors)[0]
    }
    let subTab = requiredField[errorField]
    if (subTab) {
      errorFieldName = subTab.split(/ (.*)/)
    }

    if (
      ((type === 'general' &&
        ((values.budgetType === 'DAILY' && values.budgetOptionsDaily) ||
          values.budgetType === 'OPEN' ||
          (values.budgetType === 'OVERALL' && values.budgetOptionsOverall) ||
          values.budgetType === 'OPEN') &&
        ((values.frequencyType === 'DAILY' && values.frequency) ||
          values.frequencyType === 'UNLIMITED') &&
        (((values.deliveryType === 'CUSTOM' ||
          values.budgetType === 'OVERALL') &&
          values.start &&
          values.end) ||
          values.deliveryType !== 'CUSTOM')) ||
        type === 'delivery' ||
        type === 'configuration') &&
      !invalid
    ) {
      let isValidSettingTab =
        values.title !== undefined &&
        values.dealId !== undefined &&
        values.dealDspId !== undefined &&
        values.type !== undefined &&
        values.rate !== undefined &&
        values.rate !== 0
      if (
        (type === 'delivery' && isValidSettingTab) ||
        type === 'general' ||
        type === 'configuration'
      ) {
        let params = {}
        setLoading(true)
        let postData = {
          title: values.title,
          dealId: values.dealId,
          type: values.type,
          rate: values.rate,
          pubSeats: makeArray(values.pubSeats),
          mediaPlans: values.mediaPlans,
          dealDspId: values.dealDspId,
          budgetType: values.budgetType,
          dayPartingType: values.dayPartingType,
          dayPartingTimezone: values.dayPartingTimezone,
          dayPartingSchedules: formatSchedule(values.dayPartingSchedules),
          goalMetric: values.budgetOptionsDaily,
          impression:
            (values.budgetType === 'DAILY' &&
              values.budgetOptionsDaily === 'IMPRESSION') ||
            values.budgetType === 'OVERALL'
              ? values.impression
              : null,
          //budget: values.budget,
          //seat: values.seat,
          budget:
            values.budgetType === 'DAILY' &&
            values.budgetOptionsDaily === 'BUDGET'
              ? values.budget
              : null,
          budgetOptionsDaily: values.budgetOptionsDaily,
          budgetOptionsOverall: values.budgetOptionsOverall,
          frequencyType: values.frequencyType,
          frequency: values.frequencyType === 'DAILY' ? values.frequency : null,
          deliveryType: values.deliveryType,
          start: values.start
            ? moment(values?.start).format('YYYY-MM-DD HH:mm:ss')
            : null,
          end: values.end
            ? moment(values?.end).format('YYYY-MM-DD HH:mm:ss')
            : null,

          // Restriction Data
          audienceIp: values.audienceIp,
          audienceIfa: !!values.audienceIfa,
          location: values.location,
          zipcode: !!values.zipcode,
          contentNetwork: !!values.contentNetwork,
          contentSeries: !!values.contentSeries,
          contentSeason: !!values.contentSeason,
          contentEpisode: !!values.contentEpisode,
          contentChannel: !!values.contentChannel,
          contentTitle: !!values.contentTitle,
          marginType: values.marginType,
          marginValue: values.marginValue,
        }
        if (id) {
          delete postData.mediaPlans
          params = { ...params, libraryId: id }
        }

        return api.deal
          .create(postData, params)
          .then(
            (res) => {
              if (res.data?.success) {
                toast.success({ title: 'Deal successfully created' })
                setCreated(false)
                setTimeout(() => {
                  form.reset()
                  setSelectedDsp({})
                  navigate({ pathname: '/deals/self/' + res.data.value })
                }, 500)
              } else {
                toast.error({
                  title:
                    res.data?.msg ||
                    res.data.errorDetails ||
                    'Unable to Create this deal. Please try again.',
                })
              }
            },
            (error) => {
              toast.error({ title: getErrorMessage(error) })
            }
          )
          .finally(() =>
            setTimeout(() => {
              setLoading(false)
            }, 1000)
          )
      } else {
        toast.error({
          title: 'Please fill general tab mandatory fields.',
        })
      }
    } else {
      if (isNotEmptyArray(errorFieldName)) {
        toast.error({
          title: `Please fill ${errorFieldName[1]} field in ${errorFieldName[0]} tab.`,
        })
      }
    }
  }

  if (!type) {
    return <Navigate to={`${baseLocation}/${createDealTabs[0].key}`} replace />
  }

  if (response.loading && id) {
    return <CommonSpinner />
  }

  return (
    <Form
      initialValues={initial}
      onSubmit={() => {}}
      validate={(values) => validateForm(values, type, user)}
    >
      {({
        form,
        handleSubmit,
        values,
        initialValues,
        dirty,
        invalid,
        errors,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <PageHeader
            title='Create Deal'
            actions={
              <>
                {id ? (
                  <Button
                    type='link'
                    onClick={() => navigate(`/deals/library/${id}`)}
                  >
                    Template Details
                  </Button>
                ) : null}
                <Link to={backLocation}>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  disabled={!dirty || loading}
                  loading={loading}
                  onClick={() => onSubmit(values, form, invalid, errors)}
                  htmlType='submit'
                  type='primary'
                >
                  Create
                </Button>
              </>
            }
          />

          <Grid gap={3} mt={2}>
            {createDealTabs.map((tabData) => {
              if (
                tabData.key === 'configuration' &&
                !user?.featureToggles?.bidstreamDataConfiguration
              ) {
                return null
              }
              const FormComponent = getDealFormComponent(tabData.key)
              return (
                <Box columnSpan={tabData.columnSpan} key={tabData.key}>
                  <Card title={tabData.title} height='100%' overflow='initial'>
                    <FormComponent
                      values={values}
                      initialValues={initialValues}
                      create={true}
                      form={form}
                      errors={errors}
                      libraryId={id}
                      selectedDsp={selectedDsp}
                      setSelectedDsp={setSelectedDsp}
                    />
                  </Card>
                </Box>
              )
            })}
          </Grid>
          {created ? (
            <DirtyPrompt dirty={dirty} baseLocation={baseLocation} />
          ) : null}
        </form>
      )}
    </Form>
  )
}

const getDealFormComponent = (type) => {
  switch (type) {
    case 'general':
      return SettingForm
    case 'delivery':
      return DeliveryForm
    case 'configuration':
      return ConfigurationForm
    default:
      return FourOhFour
  }
}

export default CreateDeal
