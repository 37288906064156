import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../../client-api'
import { META_TYPE } from '../../../../enums'
import { PageHeader, DirtyPrompt } from '../../../../components'
import { getErrorMessage } from '../../../../utils'
import { validateMetaForm } from '../@utils'

import { MetaForm } from '.'

const CreateMeta = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const initialValues = {
    type: META_TYPE.ADOMAIN.key,
  }

  const onSubmit = (values, form) => {
    return api.admin.meta.create(values).then(
      () => {
        toast.success({ title: 'Meta created.' })
        form.initialize(values)
        setTimeout(() => {
          navigate('..', { relative: 'path' })
        }, 300)
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateMetaForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, dirty, form }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Create Meta'
            actions={
              <>
                <Button onClick={() => form.reset()} disabled={!dirty}>
                  Reset
                </Button>
                <Button
                  type='primary'
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3} width={[1, 1, 2 / 3]}>
            <MetaForm />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default CreateMeta
