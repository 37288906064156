import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  Backdrop,
  Box,
  Button,
  Input,
  BrandCard,
  Divider,
  Flex,
  useToast,
} from '@beachfront/ui'
import { MailFilled } from '@beachfront/ui/icons'
import { Form, SubmitError, Field } from '@beachfront/ui/forms'

import logo from '../../assets/bf-logo-horizontal-white.png'
import { resolveProp, getErrorMessage } from '../../utils'
import { authService } from '../../context'

import { emailValidate } from './@utils'

const VerifyEmail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()

  const onSubmit = (values) => {
    return authService
      .verifyEmail({
        data: {
          email: values.email,
        },
      })
      .then(
        (response) => {
          if (response?.data?.success) {
            toast.success({
              title: response.data.msg || 'OTP has been resent to your email',
            })
            let pathname = resolveProp(
              location,
              'state.referrer.pathname',
              '/verify-otp'
            )
            navigate(pathname, {
              state: {
                email: values.email,
                transactionId: response.data.transactionId,
                otpLength: response.data.otpLength,
                msg: response.data.msg,
              },
            })
          } else {
            toast.error({
              title: 'User not found',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
  }

  return (
    <Backdrop preset='prism'>
      <Box width={450}>
        <Form validate={emailValidate} onSubmit={onSubmit}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <BrandCard
                logo={logo}
                fontSize={3}
                title='Verify Email'
                mode='dark'
              >
                <SubmitError mb={3} />
                <Box mb={3} fontSize={2}>
                  Enter your email address and we&apos;ll send you OTP to reset
                  your password.
                </Box>
                <Field name='email'>
                  <Input
                    size='large'
                    placeholder='Email'
                    prefix={<MailFilled />}
                  />
                </Field>
                <Button
                  type='primary'
                  htmlType='submit'
                  size='large'
                  loading={submitting}
                  block
                >
                  Verify Email
                </Button>
                <Divider />
                <Flex alignItems='center' justifyContent='flex-end'>
                  <Link to='/login'>Back to login</Link>
                </Flex>
              </BrandCard>
            </form>
          )}
        </Form>
      </Box>
      <Box pt={3} color='white'>
        &copy; {new Date().getFullYear()} Beachfront Media LLC
      </Box>
    </Backdrop>
  )
}

export default VerifyEmail
