import React, { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Link,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Tabs,
  Tooltip,
  Modal,
  useToast,
  Alert,
} from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'
import { ReloadOutlined, UploadOutlined } from '@beachfront/ui/icons'
import { useDisclosure } from '@beachfront/ui/hooks'
import moment from 'moment'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { api } from '../../../client-api'
import { useFetch, useUserData } from '../../../hooks'
import {
  DEAL_STATUS,
  DEAL_TYPE,
  MARGIN_TYPE,
  NEXT_ACTION_STATUS,
  TIMEZONE,
} from '../../../enums'
import {
  isNotEmptyObject,
  getErrorMessage,
  parseSchedule,
  formatSchedule,
  isNotEmptyArray,
  isArray,
  makeArray,
} from '../../../utils'
import {
  PageHeader,
  NetworkError,
  CommonSpinner,
  DirtyPrompt,
  PermissionError,
  TimezoneSelect,
} from '../../../components'
import FourOhFour from '../../four-oh-four'
import { dealDetailTabs, requiredField } from '../@constants'
import { validateForm } from '../@utils'

import style from './deal-detail.module.scss'

import {
  AppInfoBulkUpload,
  DealDashboard,
  SettingForm,
  DeliveryForm,
  ConfigurationForm,
  AppInfoTable,
  AppInfoModal,
  DealHistory,
} from '.'

const { TabPane } = Tabs

const DealDetail = ({ admin }) => {
  const [isStatusClick, setIsStatusClick] = useState(false)
  const { type, id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [user] = useUserData()
  const [loading, setLoading] = useState(false)
  const [isDealUpdate, setIsDealUpdate] = useState(false)
  const [selectedDsp, setSelectedDsp] = useState({})
  const [errorSubtab, setErrorSubTab] = useState({ subTab: '', refresh: false })
  const [reset, setReset] = useState('')
  const bulkUploadModal = useDisclosure()
  const appInfoModal = useDisclosure()
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)
  const baseLocation = admin ? '/admin/deals' : '/deals/self'
  const backUrl = admin ? '/admin/deals' : '/deals/self'

  const onTabChange = (key) => {
    navigate(`${baseLocation}/${id}/${key}${location.search}`)
  }

  const params = {}

  if (admin) {
    params.admin = true
  }

  const response = useFetch({
    request: api.deal.getDealById,
    payload: { id, params },
  })

  const nextAction = useMemo(() => {
    let label = NEXT_ACTION_STATUS.UNPUBLISHED.name
    let action = NEXT_ACTION_STATUS.UNPUBLISHED.key

    if (isNotEmptyArray(response?.data?.nextDealActions)) {
      const next = response.data.nextDealActions[0]
      label = NEXT_ACTION_STATUS[next]?.name
      action = next
    }

    return {
      label,
      action,
    }
  }, [response?.data?.nextDealActions])

  const onRefresh = () => {
    response.refresh()
    setErrorSubTab((prevState) => ({ ...prevState, refresh: true }))
  }

  const onStatusClick = (values, invalid, errors, approvalState) => {
    if (invalid) {
      if (
        values.type === DEAL_TYPE.FIXED.key &&
        values.mediaPlans &&
        values.mediaplanCPM !== null &&
        values.mediaplanCPM >= values.rate &&
        errors.rate === ' '
      ) {
        toast.error({
          title: 'Incorrect deal settings',
        })
      } else {
        toast.error({
          title: 'Please fill required settings fields',
        })
      }
      return
    }

    setIsStatusClick(true)

    const currentStatus = NEXT_ACTION_STATUS.fromKey(values.dealStatus)

    Modal.confirm({
      title: 'Update Deal',
      content: (
        <>
          Are you sure you want to change the status from{' '}
          <strong>{currentStatus.name}</strong> to{' '}
          <strong>
            {approvalState === NEXT_ACTION_STATUS.CHANGES_REQUESTED.key
              ? NEXT_ACTION_STATUS.CHANGES_REQUESTED.name
              : nextAction.label}
            ?
          </strong>
        </>
      ),
      okText: currentStatus.action,
      maskClosable: true,
      onCancel() {
        setIsStatusClick(false)
      },
      onOk() {
        const payload = {
          id,
          params: {
            status:
              approvalState === NEXT_ACTION_STATUS.CHANGES_REQUESTED.key
                ? NEXT_ACTION_STATUS.CHANGES_REQUESTED.key
                : nextAction.action,
          },
        }
        return api.deal.updateStatus(payload).then(
          (resp) => {
            toast.success({ title: resp.data.msg })
            queryClient.invalidateQueries({ queryKey: ['self-deals'] })
            response.refresh()
            setIsStatusClick(false)
          },
          (error) => {
            toast.error({
              title: error.message || 'Unable to process request.',
            })
            setIsStatusClick(false)
            throw error
          }
        )
      },
    })
  }

  const initialValues = useMemo(() => {
    let getData = {}
    if (response.data) {
      getData = {
        ...response.data,
        // Makes deals for AGENCY & DSP backwards compatible
        marginType: response.data.marginType ?? MARGIN_TYPE.FLAT.key,
        marginValue: response.data.marginValue ?? 0,
        mediaplanCPM: response.data.mediaplanCPM ?? null,
        allPubSeats: 'All seats are selected',
        status: response.data.status ? 'ENABLE' : 'DISABLE',
        flightDate: response.data.flightDate ? 'DATE' : 'ONGOING',
        dayPartingType: response.data.dayPartingType || 'ALL',
        dayPartingSchedules:
          parseSchedule(response.data?.dayPartingSchedules) || [],
        dayPartingTimezone:
          response.data.dayPartingTimezone || 'America/New_York',
      }
      getData.budgetOptionsOverall = 'IMPRESSION'
      if (response.data.audienceIp === 'true') {
        getData.audienceIp = 'on'
      }
      if (response.data.location === 'true') {
        getData.location = 'on'
      }
      if (!response.data.audienceIp || response.data.audienceIp === 'false') {
        getData.audienceIp = 'off'
      }
      if (!response.data.location || response.data.location === 'false') {
        getData.location = 'off'
      }
      if (response.data?.goalMetric && response.data?.budgetType) {
        getData.budgetOptionsDaily = response.data?.goalMetric
      }
      if (!response.data?.goalMetric) {
        getData.budgetOptionsDaily = 'BUDGET'
      }
      const {
        contentChannel,
        contentEpisode,
        contentSeason,
        contentTitle,
        contentNetwork,
        contentSeries,
      } = response.data
      if (
        contentChannel &&
        contentEpisode &&
        contentSeason &&
        contentTitle &&
        contentNetwork &&
        contentSeries
      ) {
        getData.contentAll = true
      }
      let startDate = moment(getData.start, 'YYYY-MM-DD HH:mm:ss').valueOf()
      let endDate = moment(getData.end, 'YYYY-MM-DD HH:mm:ss').valueOf()
      if (getData.start) {
        getData.start = moment(Number(startDate))
      }
      if (getData.end) {
        getData.end = moment(Number(endDate))
      }
      if (response.data?.selectedDsp || response.data?.selectedPubDsp) {
        setSelectedDsp(
          isNotEmptyObject(response.data?.selectedPubDsp)
            ? response.data?.selectedPubDsp
            : response.data?.selectedDsp
        )
      }
    }
    return getData
  }, [response.data])

  const onSubmit = (values, form, invalid, errors) => {
    let errorFieldName = ''
    let errorField = ''

    // toggle class name
    setTimeout(() => setReset(true), 1000)

    if (isNotEmptyObject(errors)) {
      errorField = Object.keys(errors)[0]
      setErrorSubTab((prevState) => ({ ...prevState, refresh: false }))
    }

    let subTab = requiredField[errorField]
    if (subTab) {
      errorFieldName = subTab.split(/ (.*)/)
    }

    setErrorSubTab((prevState) => ({ ...prevState, subTab: errorFieldName[0] }))
    setReset('errorTab')
    form.submit()

    if (invalid) {
      if (
        values.type === DEAL_TYPE.FIXED.key &&
        values.mediaPlans &&
        values.mediaplanCPM !== null &&
        values.mediaplanCPM >= values.rate &&
        errors.rate === ' '
      ) {
        toast.error({
          title: 'Incorrect deal settings',
        })
      } else if (isArray(errorFieldName)) {
        toast.error({
          title: `Please fill ${errorFieldName[1]} field in ${errorFieldName[0]} tab`,
        })
      }
    } else {
      if (
        (type === 'general' &&
          ((values.budgetType === 'DAILY' && values.budgetOptionsDaily) ||
            values.budgetType === 'OPEN' ||
            (values.budgetType === 'OVERALL' && values.budgetOptionsOverall) ||
            values.budgetType === 'OPEN') &&
          ((values.frequencyType === 'DAILY' && values.frequency) ||
            values.frequencyType === 'UNLIMITED') &&
          (((values.deliveryType === 'CUSTOM' ||
            values.budgetType === 'OVERALL') &&
            values.start &&
            values.end) ||
            values.deliveryType !== 'CUSTOM')) ||
        type === 'delivery' ||
        type === 'configuration'
      ) {
        let isValidSettingTab =
          values.title !== undefined &&
          values.dealId !== undefined &&
          values.dealDspId !== undefined &&
          values.type !== undefined &&
          values.rate !== undefined &&
          values.rate !== 0
        if (
          (type === 'delivery' && isValidSettingTab) ||
          type === 'general' ||
          type === 'configuration'
        ) {
          let postData = {
            title: values.title,
            dealId: values.dealId,
            dayPartingSchedules: formatSchedule(values.dayPartingSchedules),
            dayPartingTimezone: values.dayPartingTimezone,
            dayPartingType: values.dayPartingType,
            type: values.type,
            rate: values.rate,
            pubSeats: makeArray(values.pubSeats),
            mediaPlans: values.mediaPlans,
            dealDspId: values.dealDspId,
            budgetType: values.budgetType,
            goalMetric:
              values.budgetType === 'DAILY'
                ? values.budgetOptionsDaily
                : values.budgetType === 'OVERALL'
                  ? values.budgetOptionsOverall
                  : null,
            impression:
              (values.budgetType === 'DAILY' &&
                values.budgetOptionsDaily === 'IMPRESSION') ||
              values.budgetType === 'OVERALL'
                ? values.impression
                : null,
            frequencyType: values.frequencyType,
            seat: values.seat,
            budget:
              values.budgetType === 'DAILY' &&
              values.budgetOptionsDaily === 'BUDGET'
                ? values.budget
                : null,
            frequency:
              values.frequencyType === 'DAILY' ? values.frequency : null,
            deliveryType: values.deliveryType,
            start: values.start
              ? moment(values?.start).format('YYYY-MM-DD HH:mm:ss')
              : null,
            end: values.end
              ? moment(values?.end).format('YYYY-MM-DD HH:mm:ss')
              : null,

            // Restriction Data
            audienceIp: values.audienceIp,
            audienceIfa: !!values.audienceIfa,
            location: values.location,
            zipcode: !!values.zipcode,
            contentNetwork: !!values.contentNetwork,
            contentSeries: !!values.contentSeries,
            contentSeason: !!values.contentSeason,
            contentEpisode: !!values.contentEpisode,
            contentChannel: !!values.contentChannel,
            contentTitle: !!values.contentTitle,
            marginType: values.marginType,
            marginValue: values.marginValue,
          }
          setLoading(true)
          api.deal
            .update(id, postData)
            .then(
              (res) => {
                if (res.data?.success) {
                  toast.success({ title: 'Deal updated.' })
                  queryClient.invalidateQueries({ queryKey: ['self-deals'] })
                  setIsDealUpdate(false)
                  setTimeout(() => {
                    response.refresh()
                    setSelectedDsp({})
                  }, 100)
                } else {
                  toast.error({
                    title:
                      res.data?.msg ||
                      res.data.errorDetails ||
                      'Unable to update this deal. Please try again.',
                  })
                }
              },
              (error) => {
                toast.error({ title: getErrorMessage(error) })
              }
            )
            .finally(() => setLoading(false))
        } else {
          setLoading(false)
          toast.error({
            title: 'Please fill setting tab mandatory fields',
          })
        }
      } else {
        setLoading(false)
        toast.error({
          title: 'Please fill delivery tab mandatory fields',
        })
      }
    }
  }

  if (!type || type === 'settings') {
    return (
      <Navigate
        to={{
          pathname: `${baseLocation}/${id}/${dealDetailTabs[0].key}`,
          search: location.search,
        }}
        replace
      />
    )
  }

  if (response.error) {
    if (user.admin && !admin) {
      return (
        <Navigate
          to={{
            pathname: `/admin/deals/${id}/${dealDetailTabs[0].key}`,
            search: '',
          }}
        />
      )
    }
    return (
      <NetworkError
        description={response.error || 'Deal not found'}
        buttonLabel='Back to Deal List'
        onAction={() => navigate(backUrl)}
      />
    )
  }

  const getTooltip = (errors, pristine) => {
    if (!response?.data?.mediaPlans) {
      return 'Update this deal with a media plan before publishing.'
    }
    if (response?.data?.mediaPlans && isNotEmptyObject(errors)) {
      return 'Update the deal with mandatory field before publishing.'
    }
    if (response?.data?.mediaPlans && !isNotEmptyObject(errors) && !pristine) {
      return 'Save your changes before publishing.'
    }
    return ''
  }

  const onDelete = () => {
    Modal.confirm({
      title: 'Delete Deal',
      content:
        response?.data?.dealStatus === DEAL_STATUS.PUBLISHED.key
          ? `Deal will be unpublished after deletion. Are you sure you want to delete "${response.data?.title}"?`
          : `Are you sure you want to delete "${response.data?.title}"?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.deal.delete(id).then(
          () => {
            toast.success({ title: 'Deal deleted.' })
            queryClient.invalidateQueries({ queryKey: ['self-deals'] })
            navigate(backUrl)
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        validate={(values) => validateForm(values, type, user)}
        onSubmit={() => {}}
      >
        {({
          handleSubmit,
          submitting,
          values,
          initialValues,
          form,
          dirty,
          errors,
          pristine,
          invalid,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: 'calc(100% - 18px)' }}>
            <PageHeader
              title={
                <>
                  Edit Deal {'- '}
                  <Tooltip title={response?.data?.title} placement='bottomLeft'>
                    {response?.data?.title}
                  </Tooltip>
                </>
              }
              actions={
                <>
                  {type === 'dashboard' ? (
                    <Box minWidth={160}>
                      <TimezoneSelect value={timezone} onChange={setTimezone} />
                    </Box>
                  ) : null}
                  {response?.data?.dealStatus !== DEAL_STATUS.INACTIVE.key &&
                  !admin ? (
                    <Button
                      onClick={onDelete}
                      disabled={response.data?.dealDisabled}
                      danger
                    >
                      Delete
                    </Button>
                  ) : null}
                  <Link to={backUrl}>
                    <Button>Cancel</Button>
                  </Link>
                  {type !== 'dashboard' && type !== 'history' && !admin ? (
                    <Button
                      disabled={
                        isDealUpdate || bulkUploadModal.isOpen || !dirty
                      }
                      loading={loading}
                      onClick={() => onSubmit(values, form, invalid, errors)}
                      htmlType='submit'
                      type='primary'
                    >
                      Update
                    </Button>
                  ) : null}
                  {type === 'appinfooverride' && !admin ? (
                    <>
                      <Button
                        onClick={() => appInfoModal.open()}
                        disabled={
                          response.data?.dealDisabled ||
                          response.data?.dealStatus === DEAL_STATUS.INACTIVE.key
                        }
                      >
                        Add App Info
                      </Button>
                      <Button
                        type='primary'
                        icon={<UploadOutlined />}
                        onClick={() => bulkUploadModal.open()}
                        disabled={
                          response.data?.dealDisabled ||
                          response.data?.dealStatus === DEAL_STATUS.INACTIVE.key
                        }
                      >
                        Bulk Upload
                      </Button>
                    </>
                  ) : null}
                  {type === 'general' &&
                  values.dealStatus &&
                  !admin &&
                  isNotEmptyArray(response?.data?.nextDealActions) &&
                  !response?.data?.nextDealActions.includes(
                    'CHANGES_REQUESTED'
                  ) ? (
                    <Tooltip
                      title={getTooltip(errors, pristine)}
                      placement='bottom'
                    >
                      <Button
                        type='primary'
                        loading={submitting}
                        onClick={() => onStatusClick(values, invalid, errors)}
                        disabled={
                          !response?.data?.mediaPlans ||
                          errors.rate ||
                          !pristine ||
                          isStatusClick
                        }
                      >
                        {nextAction.label}
                      </Button>
                    </Tooltip>
                  ) : null}
                  {response?.data?.nextDealActions.includes(
                    'CHANGES_REQUESTED'
                  ) ? (
                    <Flex gap={2}>
                      <Button
                        onClick={() =>
                          onStatusClick(
                            values,
                            invalid,
                            errors,
                            NEXT_ACTION_STATUS.APPROVED.key
                          )
                        }
                        disabled={isStatusClick}
                        style={
                          !isStatusClick
                            ? {
                                borderColor: '#2BAD91',
                                color: '#2BAD91',
                              }
                            : null
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        danger
                        disabled={isStatusClick}
                        onClick={() =>
                          onStatusClick(
                            values,
                            invalid,
                            errors,
                            NEXT_ACTION_STATUS.CHANGES_REQUESTED.key
                          )
                        }
                      >
                        Request Changes
                      </Button>
                    </Flex>
                  ) : null}
                  <Button
                    onClick={onRefresh}
                    icon={<ReloadOutlined />}
                    style={{ flex: 'none' }}
                  />
                </>
              }
            />
            {type === 'configuration' &&
            !user?.featureToggles?.bidstreamDataConfiguration ? (
              <PermissionError
                customPath={
                  admin
                    ? `/admin/deals/${id}/dashboard`
                    : `/deals/self/${id}/dashboard`
                }
                customTitle='Deal-Dashboard'
              />
            ) : null}
            <DirtyPrompt dirty={dirty} baseLocation={`/deals/self/${id}`} />
            {type !== 'configuration' ||
            user?.featureToggles?.bidstreamDataConfiguration ? (
              <Box mt={3}>
                <Tabs activeKey={type} onChange={onTabChange} type='card'>
                  {dealDetailTabs.map((tabData) => {
                    if (
                      (tabData.key === 'appinfooverride' &&
                        !user?.featureToggles?.appOverrideAccess) ||
                      (tabData.key === 'configuration' &&
                        !user?.featureToggles?.bidstreamDataConfiguration)
                    ) {
                      return null
                    }
                    const FormComponent = getDealFormComponent(tabData.key)
                    let params = {}

                    if (tabData.key === 'history') {
                      params = { id }
                    }

                    if (admin) {
                      params.admin = true
                    }

                    return (
                      <TabPane
                        key={tabData.key}
                        tab={
                          <Box
                            className={
                              tabData.title === errorSubtab.subTab
                                ? style[reset]
                                : cx({ activeClass: true })
                            }
                            style={{
                              color:
                                tabData.title === errorSubtab.subTab &&
                                !errorSubtab.refresh
                                  ? 'red'
                                  : undefined,
                            }}
                          >
                            {tabData.title}
                          </Box>
                        }
                      >
                        {!response.loading ? (
                          <>
                            {response.data?.dealStatus ===
                            DEAL_STATUS.INACTIVE.key ? (
                              <Alert
                                message='Inactive Deal can not be edited.'
                                type='info'
                                style={{ margin: '0 0 5px' }}
                                banner
                                showIcon
                                closable
                              />
                            ) : null}
                            <FormComponent
                              dealId={id}
                              params={params}
                              values={values}
                              timezone={timezone}
                              initialValues={initialValues}
                              superAdmin={admin}
                              form={form}
                              disableDeal={
                                response.data?.locked ||
                                response.data?.dealDisabled
                              }
                              disableForm={
                                response.data?.dealDisabled ||
                                response.data?.dealStatus ===
                                  DEAL_STATUS.INACTIVE.key
                              }
                              errors={errors}
                              selectedDsp={selectedDsp}
                              setSelectedDsp={setSelectedDsp}
                            />
                          </>
                        ) : (
                          <CommonSpinner />
                        )}
                      </TabPane>
                    )
                  })}
                </Tabs>
              </Box>
            ) : null}
            <AppInfoModal
              title='Add App Info'
              dealId={id}
              isOpen={appInfoModal.isOpen}
              onClose={appInfoModal.close}
            />
            {bulkUploadModal.isOpen ? (
              <AppInfoBulkUpload
                dealId={id}
                isOpen={bulkUploadModal.isOpen}
                onClose={bulkUploadModal.close}
              />
            ) : null}
          </form>
        )}
      </Form>
    </>
  )
}

DealDetail.propTypes = {
  admin: PropTypes.bool,
}

const getDealFormComponent = (type) => {
  switch (type) {
    case 'dashboard':
      return DealDashboard
    case 'general':
      return SettingForm
    case 'delivery':
      return DeliveryForm
    case 'configuration':
      return ConfigurationForm
    case 'appinfooverride':
      return AppInfoTable
    case 'history':
      return DealHistory
    default:
      return FourOhFour
  }
}

export default DealDetail
