import React, { useMemo, useState } from 'react'
import { Box, Button, useToast } from '@beachfront/ui'
import { useNavigate } from 'react-router-dom'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../../../client-api'
import { useFetch } from '../../../../../hooks'
import { getUniqueId, getErrorMessage } from '../../../../../utils'
import { ACCOUNT_STATUS, ACCOUNT_TYPE, MARGIN_TYPE } from '../../../../../enums'
import { PageHeader, DirtyPrompt } from '../../../../../components'
import {
  validateAccountCreateForm,
  buildLinkData,
  filterSourceProviders,
} from '../../@utils'
import { AccountForm } from '..'

const CreateAccount = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [segmentProviderList, setSegmentProviderList] = useState([])

  const response = useFetch({
    request: api.admin.account.getAccountDsps,
  })

  const onSubmit = (values, form) => {
    const { sharedSegment, ...formData } = values
    formData.shared = sharedSegment
    if (formData.sourceProviders) {
      formData.sourceProviders = filterSourceProviders(
        formData.sourceProviders,
        segmentProviderList
      )
    }
    const result = {
      ...formData,
      accManagerEmails: formData.demoAccount ? [] : formData.accManagerEmails,
      linkData: buildLinkData(values),
      sourceProviders: formData.shared ? formData.sourceProviders : [],
    }

    if (!formData.bfmMarginEnabled) {
      result.bfmMarginType = null
      result.bfmMarginValue = 0
    }

    return api.admin.account.create(result).then(
      () => {
        toast.success({ title: 'Account created.' })
        form.initialize(values)
        setTimeout(() => {
          navigate('..', { relative: 'path' })
        }, 300)
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const initialValues = useMemo(() => {
    const id = getUniqueId()
    return {
      bfmMarginEnabled: true,
      bfmMarginType: MARGIN_TYPE.PERCENT.key,
      bfmMarginValue: 10,
      status: ACCOUNT_STATUS.ACTIVE.key,
      type: ACCOUNT_TYPE.DSP.key,
      allowedAds: 50,
      link: [
        {
          key: id,
          advertiserId: '',
          seatAgencyId: '',
          seatOrAgency: 'seat',
          adomain: '',
        },
      ],
      [`link-${id}-seatOrAgency`]: 'seat',
    }
  }, [])

  return (
    <Form
      initialValues={initialValues}
      validate={validateAccountCreateForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, dirty, form }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Create Account'
            actions={
              <>
                <Button onClick={() => form.reset()} disabled={!dirty}>
                  Reset
                </Button>
                <Button
                  type='primary'
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3}>
            <AccountForm
              response={response}
              create={true}
              setSegmentProviderList={setSegmentProviderList}
            />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default CreateAccount
