import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Flex, Box, Button } from '@beachfront/ui'
import { ReloadOutlined } from '@beachfront/ui/icons'

import { api } from '../../client-api'
import { getErrorMessage } from '../../utils'
import { useContentSize, useFilters, useInfiniteTable } from '../../hooks'
import { PageHeader, InfiniteTable, NetworkError } from '../../components'

import { mediaListColumns } from './@constants'
import { MediaListFilter } from './@components'

const MediaLists = ({ title, mediaListType, filterKey }) => {
  const { contentHeight } = useContentSize()
  const navigate = useNavigate()

  const filterRef = useRef()
  const [filters, setFilters] = useFilters(filterKey, {
    schema: {
      createDate: 'date',
      updateDate: 'date',
    },
  })

  const table = useInfiniteTable({
    queryKey: ['media-lists', mediaListType],
    queryFn: api.mediaList[mediaListType],
    filters: filters.populated,
  })

  const scroll = {
    x: '100%',
    y: contentHeight - 260,
  }

  const headerActions = (
    <>
      <Button
        onClick={table.refetch}
        icon={<ReloadOutlined />}
        style={{ flex: 'none' }}
      />
      <Button onClick={() => navigate('create')}>Create Media List</Button>
      <Button type='primary' onClick={() => navigate('upload')}>
        Upload Media List
      </Button>
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar py={2}>
      <Flex justifyContent='space-between'>
        <MediaListFilter
          filterRef={filterRef}
          filters={filters.all}
          setFilters={setFilters}
        />
      </Flex>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title={title} actions={headerActions} />
      <Box mt={2}>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <InfiniteTable
            loading={table.isFetching}
            rowKey='id'
            emptyText='No Media Lists Found'
            columns={mediaListColumns}
            toolbar={toolbar}
            scroll={scroll}
            filters={filters.populated}
            onFilter={(key) => filterRef.current?.addFilter(key)}
            {...table.props}
          />
        )}
      </Box>
    </>
  )
}

MediaLists.propTypes = {
  title: PropTypes.node,
  mediaListType: PropTypes.string,
  filterKey: PropTypes.string,
}

export default MediaLists
