import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import {
  Card,
  Box,
  Flex,
  Grid,
  Button,
  Tooltip,
  Skeleton,
  Spinner,
} from '@beachfront/ui'
import {
  ZoomOutOutlined,
  LeftOutlined,
  RightOutlined,
} from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { TIMEZONE } from '../../../enums'
import { isNotEmptyArray, isNumber } from '../../../utils'
import { useBuyers } from '../../../hooks'
import {
  MetricCard,
  TimezoneSelect,
  SelectField,
  NetworkError,
} from '../../../components'
import { AreaChart, BandChart } from '../../../components/chart'

import TargetingCpmExplorer from './targeting-cpm-explorer'
import TargetingInsights from './targeting-insights'

const TargetingWidgets = ({
  targetingParams,
  availsDsp,
  timezone,
  chartType,
  marginType,
  marginValue,
  dealFloor,
  dataCost,
  chartData,
  widgetsData,
  isChartLoading,
  isWidgetsLoading,
  onAvailsDspChange,
  onTimezoneChange,
  onChartTypeChange,
  onMarginTypeChange,
  onMarginValueChange,
  onDealFloorChange,
}) => {
  const { minCpmProp, maxCpmProp } = chartData?.graph ?? {}
  const metrics = chartData?.graph?.summary ?? chartData?.chart?.summary
  const chartDataLength = chartData?.graph?.data?.length ?? 0
  const chartHeight = 270

  const theme = useTheme()
  const [subIndex, setSubIndex] = useState(null)
  const [bottomTabKey, setBottomTabKey] = useState('insights')
  const [prevChartData, setPrevChartData] = useState(chartData)
  const [priceRange, setPriceRange] = useState([minCpmProp, maxCpmProp])
  const [subPriceRange, setSubPriceRange] = useState(null)

  const { data: dspData } = useBuyers()

  const currentChart =
    chartData?.graph?.data?.[subIndex]?.subChart ?? chartData?.graph

  if (chartData !== prevChartData) {
    setPrevChartData(chartData)
    setSubIndex(null)
    setSubPriceRange(null)
    setPriceRange([minCpmProp, maxCpmProp])
  }

  useEffect(() => {
    const currentChart =
      chartData?.graph?.data?.[subIndex]?.subChart ?? chartData?.graph

    if (currentChart) {
      const { minCpm, maxCpm } = currentChart
      setPriceRange([minCpm, maxCpm])
    }
  }, [chartData, subIndex])

  const onShiftLeft = () => {
    setSubIndex((i) => i - 1)
    setSubPriceRange(null)
  }

  const onShiftRight = () => {
    setSubIndex((i) => i + 1)
    setSubPriceRange(null)
  }

  const onZoomOut = () => {
    setSubIndex(null)
    setSubPriceRange(null)
  }

  const onChartClick = ({ activeTooltipIndex: i }) => {
    if (subIndex === null && isNumber(i)) {
      setSubIndex(i)
    }
    if (isNumber(subIndex)) {
      const currentChart =
        chartData?.graph?.data?.[subIndex]?.subChart ?? chartData?.graph

      if (currentChart) {
        const [minStr, maxStr] = currentChart.data[i].x.split(' - ')
        const minCpm = parseFloat(minStr.replace('$', ''))
        const maxCpm = parseFloat(maxStr.replace('$', ''))
        setSubPriceRange([minCpm, maxCpm])
      }
    }
  }

  const getMetricTooltip = (metric) => {
    if (metric.msg === 'Monthly Avails' || metric.msg === 'Daily Avails') {
      return 'The avails data is approximate'
    }
    return ''
  }

  const tabBarExtra = (
    <Flex gap={2}>
      <Box width={160}>
        <SelectField
          size='small'
          data={dspData ?? []}
          value={availsDsp}
          onChange={onAvailsDspChange}
          keyField='key'
          textField='name'
          placeholder='Select DSP'
          allowClear
        />
      </Box>
      <Box width={160}>
        <TimezoneSelect
          size='small'
          data={[TIMEZONE.UTC, TIMEZONE.EST]}
          value={timezone}
          onChange={onTimezoneChange}
        />
      </Box>
    </Flex>
  )

  return (
    <Box>
      {isChartLoading ? (
        <Skeleton mb={3} height={50} />
      ) : isNotEmptyArray(metrics) ? (
        <Grid mb={3} gap={2} columns='repeat(auto-fit, minmax(150px, 1fr))'>
          {metrics.map((m, i) => (
            <MetricCard
              key={i}
              index={i}
              label={m.msg}
              value={m.data}
              tooltip={getMetricTooltip(m)}
            />
          ))}
        </Grid>
      ) : null}
      <Card
        tabList={[
          { key: 'day', tab: 'Daily Requests' },
          { key: 'cpm', tab: 'Requests vs CPM' },
        ]}
        activeTabKey={chartType}
        onTabChange={onChartTypeChange}
        tabBarExtraContent={tabBarExtra}
        bodyStyle={{ position: 'relative' }}
      >
        {chartType === 'day' ? (
          <Box minHeight={chartHeight}>
            {isChartLoading ? (
              <Spinner mask />
            ) : chartData?.success && currentChart ? (
              <AreaChart
                plotProps={{ height: chartHeight }}
                data={currentChart.data}
                tooltipLabel={currentChart.toolTipLabel}
                xAxisLabel={currentChart.xAxisLabel}
                yAxisLabel={currentChart.yAxisLabel}
              />
            ) : (
              <NetworkError
                description={chartData?.msg || 'No enough data to build chart'}
              />
            )}
          </Box>
        ) : null}
        {chartType === 'cpm' ? (
          <Box minHeight={chartHeight}>
            {isChartLoading ? (
              <Spinner mask />
            ) : chartData?.success && currentChart ? (
              <>
                <Flex mb={1} gap={2} justifyContent='center'>
                  <Box
                    height={24}
                    fontSize={0}
                    color='placeholder'
                    hidden={subIndex !== null}
                  >
                    Click a bar to drill down price range
                  </Box>
                  <Flex gap={2} hidden={subIndex === null}>
                    <Tooltip placement='top' title='Decrease Price Range'>
                      <Button
                        size='small'
                        icon={<LeftOutlined />}
                        disabled={subIndex === 0}
                        onClick={onShiftLeft}
                      />
                    </Tooltip>
                    <Tooltip placement='top' title='Zoom Out'>
                      <Button
                        size='small'
                        icon={<ZoomOutOutlined />}
                        onClick={onZoomOut}
                      />
                    </Tooltip>
                    <Tooltip placement='top' title='Increase Price Range'>
                      <Button
                        size='small'
                        icon={<RightOutlined />}
                        disabled={subIndex === chartDataLength - 1}
                        onClick={onShiftRight}
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
                <BandChart
                  plotProps={{ height: chartHeight - 28 }}
                  data={currentChart.data}
                  tooltipLabel={currentChart.toolTipLabel}
                  xAxisLabel={currentChart.xAxisLabel}
                  yAxisLabel={currentChart.yAxisLabel}
                  xDomain={[currentChart.minCpm, currentChart.maxCpm]}
                  yScale='log'
                  barColor={
                    subIndex === null
                      ? theme.colors.primary.base
                      : theme.colors.cyan.base
                  }
                  onClick={onChartClick}
                />
              </>
            ) : (
              <NetworkError
                description={chartData?.msg || 'No enough data to build chart'}
              />
            )}
          </Box>
        ) : null}
      </Card>
      <Card
        mt={3}
        overflow='initial'
        tabList={[
          { key: 'insights', tab: 'Insights' },
          { key: 'cpm', tab: 'CPM Explorer' },
        ]}
        activeTabKey={bottomTabKey}
        onTabChange={setBottomTabKey}
      >
        <Box hidden={bottomTabKey !== 'insights'}>
          <TargetingInsights
            widgetsData={widgetsData}
            isLoading={isWidgetsLoading}
            targetingParams={targetingParams}
            timezone={timezone}
            minCpm={minCpmProp}
            maxCpm={maxCpmProp}
            marginType={marginType}
            marginValue={marginValue}
            dealFloor={dealFloor}
            dataCost={dataCost}
            priceRange={priceRange}
            subPriceRange={subPriceRange}
            priceRangeEnabled={chartType === 'cpm'}
            onClearSubPriceRange={() => setSubPriceRange(null)}
          />
        </Box>
        <Box hidden={bottomTabKey !== 'cpm'}>
          <TargetingCpmExplorer
            minCpm={minCpmProp}
            maxCpm={maxCpmProp}
            marginType={marginType}
            marginValue={marginValue}
            dealFloor={dealFloor}
            onMarginTypeChange={onMarginTypeChange}
            onMarginValueChange={onMarginValueChange}
            onDealFloorChange={onDealFloorChange}
          />
        </Box>
      </Card>
    </Box>
  )
}

TargetingWidgets.propTypes = {
  targetingParams: PropTypes.object,
  availsDsp: PropTypes.string,
  timezone: PropTypes.string,
  chartType: PropTypes.string,
  marginType: PropTypes.string,
  marginValue: PropTypes.number,
  dealFloor: PropTypes.number,
  dataCost: PropTypes.number,
  chartData: PropTypes.object,
  widgetsData: PropTypes.array,
  isChartLoading: PropTypes.bool,
  isWidgetsLoading: PropTypes.bool,
  onAvailsDspChange: PropTypes.func,
  onTimezoneChange: PropTypes.func,
  onChartTypeChange: PropTypes.func,
  onMarginTypeChange: PropTypes.func,
  onMarginValueChange: PropTypes.func,
  onDealFloorChange: PropTypes.func,
}

export default TargetingWidgets
