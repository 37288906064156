import React from 'react'
import { Button, Heading, Text, Flex } from '@beachfront/ui'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import logoBlack from '../../assets/bf-logo-horizontal-black.png'
import logoWhite from '../../assets/bf-logo-horizontal-white.png'

const FourOhFour = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Flex
      gap={3}
      minHeight={300}
      height='calc(100vh - 80px)'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <Link to='/'>
        <img
          alt='Beachfront Media'
          src={theme.name === 'light' ? logoBlack : logoWhite}
          width={320}
        />
      </Link>
      <Heading level={1}>404</Heading>
      <Text>
        The page you are trying to access does not exist. Use the button below
        to get back on track.
      </Text>
      <Button type='primary' onClick={() => navigate('/', { replace: true })}>
        Return to Home
      </Button>
    </Flex>
  )
}

export default FourOhFour
