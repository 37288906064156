import { useEffect } from 'react'
import { useCallbackRef } from '@beachfront/ui/hooks'

export const useIntersectionObserver = ({ element, root, onIntersect }) => {
  const callback = useCallbackRef(onIntersect)

  useEffect(() => {
    const el = element
    const options = {
      root: root ?? null,
      rootMargin: '0px',
      threshold: [0, 1],
    }
    const observer = new IntersectionObserver(callback, options)
    if (el) {
      observer.observe(el)
    }
    return () => {
      if (el) {
        observer.unobserve(el)
      }
    }
  }, [element, root, callback])
}
