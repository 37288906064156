import {
  ADMIN_URLS,
  API_KEY_URLS,
  BOOLEAN_SEGMENT_URLS,
  COMMON_URLS,
  DASHBOARD_URLS,
  DEAL_URLS,
  DSP_DEAL_URLS,
  INSIGHT_URLS,
  MEDIALIST_URLS,
  MEDIAPLAN_URLS,
  REACH_URLS,
  REALTIME_URLS,
  REPORT_URL,
  SEGMENT_URLS,
  SUPPORT_URLS,
  USER_URLS,
  USERS_URLS,
} from './branches'

export default {
  admin: ADMIN_URLS,
  apiKey: API_KEY_URLS,
  booleanSegment: BOOLEAN_SEGMENT_URLS,
  common: COMMON_URLS,
  dashboard: DASHBOARD_URLS,
  deal: DEAL_URLS,
  dspDeal: DSP_DEAL_URLS,
  insight: INSIGHT_URLS,
  mediaList: MEDIALIST_URLS,
  mediaPlan: MEDIAPLAN_URLS,
  reach: REACH_URLS,
  realtime: REALTIME_URLS,
  report: REPORT_URL,
  segment: SEGMENT_URLS,
  support: SUPPORT_URLS,
  user: USER_URLS,
  users: USERS_URLS,
}
