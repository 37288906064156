import { useQuery } from '@tanstack/react-query'

import { http } from '../../client-api'
import { isNotEmptyArray, isNumber } from '../../utils'
import { IS_DEALS_ENGINE } from '../../constants'

export const useTargetingInsight = ({ params, enabled } = {}) => {
  return useQuery({
    queryKey: ['targeting', 'insight', params],
    queryFn: async ({ signal }) => {
      const {
        widget,
        targetingParams,
        timezone,
        priceRange,
        marginType,
        marginValue,
        dealFloor,
        dataCost = 0,
      } = params

      const url = widget.apiUrl
      const payload = String(url).includes('timezone=') ? {} : { timezone }

      payload.dataCost = dataCost
      payload.estimatedDealFloor = dealFloor

      if (!IS_DEALS_ENGINE) {
        payload.dealMarginType = marginType
        payload.dealMarginValue = marginValue
      }

      if (isNotEmptyArray(priceRange) && priceRange.every(isNumber)) {
        payload.minCpm = priceRange[0]
        payload.maxCpm = priceRange[1]
      }

      const request =
        widget.methodType === 'POST'
          ? http.post(url, targetingParams, { params: payload, signal })
          : http.get(url, { params: payload, signal })

      const res = await request
      return res.data?.data
    },
    staleTime: 60000,
    enabled,
  })
}
