import { useQuery } from '@tanstack/react-query'

import { api } from '../../client-api'

export const useBuyers = () => {
  return useQuery({
    queryKey: ['buyers'],
    queryFn: async () => {
      const res = await api.common.buyers()
      return res.data?.data
    },
  })
}
