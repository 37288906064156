import React, { useContext } from 'react'
import { Flex, Box, Layout } from '@beachfront/ui'

import logoSquare from '../../assets/bf-logo-square.png'
import logoHorizontal from '../../assets/bf-logo-horizontal-white.png'

const BrandLogo = () => {
  const { siderCollapsed } = useContext(Layout.SiderContext)

  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      overflow='hidden'
      px={2}
      height={64}
      minHeight={64}
    >
      <Box
        as='img'
        alt='Beachfront Media'
        width={50}
        src={logoSquare}
        hidden={!siderCollapsed}
      />
      <Box
        as='img'
        alt='Beachfront Media'
        width={170}
        src={logoHorizontal}
        hidden={siderCollapsed}
      />
    </Flex>
  )
}

export default BrandLogo
