import { createEnum } from '../utils'

export const PERMISSION = createEnum({
  TRANSPARENCY: {
    key: 'transparency',
  },
  USERS: {
    key: 'users',
  },
  DEALS: {
    key: 'deals',
  },
  DEAL: {
    key: 'deal',
  },
  MEDIA_PLAN: {
    key: 'media-plan',
  },
  ADMIN: {
    key: 'admin',
  },
  DEALS_ADMIN: {
    key: 'deals-admin',
  },
  AUDIENCE_SEGMENT: {
    key: 'audience-segment',
  },
  AUDIENCE_SEGMENT_SHARED: {
    key: 'audience-segment-shared',
  },
  MEDIA_LIST: {
    key: 'media-list',
  },
  DASHBOARD: {
    key: 'dashboard',
  },
  API_KEY: {
    key: 'apikey',
  },
  REPORT: {
    key: 'report',
  },
  REACH: {
    key: 'reach',
  },
  SUPPORT: {
    key: 'support',
  },
})
