import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Grid, Box, Card, Button, Table, useToast } from '@beachfront/ui'
import { Form, Field } from '@beachfront/ui/forms'
import { ReloadOutlined } from '@beachfront/ui/icons'
import { useTableQuery } from '@beachfront/ui/hooks'

import { api } from '../../../client-api'
import { MEDIA_LIST_TYPE, LIST_INPUT_TYPE } from '../../../enums'
import { useContentSize, useMediaAttachList } from '../../../hooks'
import { getErrorMessage } from '../../../utils'
import {
  PageHeader,
  PageSearch,
  SelectField,
  DirtyPrompt,
} from '../../../components'
import FourOhFour from '../../four-oh-four'
import { validateMediaListForm } from '../@utils'
import { attachMediaColumns } from '../@constants'

import { MediaListForm, MediaListUploadForm } from '.'

const CreateMediaList = ({ isUpload }) => {
  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { contentWidth, contentHeight } = useContentSize()

  const [searchQuery, setSearchQuery] = useState('')
  const [mediaType, setMediaType] = useState(params.mediaType)
  const response = useMediaAttachList(mediaType)

  const matchesQuery = (dt) => {
    return String(dt.title).toLowerCase().includes(searchQuery.toLowerCase())
  }

  const applyQuery = (data) => {
    return searchQuery ? data.filter(matchesQuery) : data
  }

  const table = useTableQuery({
    data: applyQuery(response.data?.content ?? []),
  })

  useEffect(() => {
    setSearchQuery('')
    table.setSelectedRowKeys([])
  }, [mediaType])

  const rowKey = mediaType === MEDIA_LIST_TYPE.METRO_CODE.key ? 'code' : 'title'

  const scroll = {
    y: contentHeight - 248,
    x: contentWidth - 800,
  }

  const initialValues = {
    mediaType: params.mediaType,
    inputType: isUpload
      ? LIST_INPUT_TYPE.UPLOAD.key
      : LIST_INPUT_TYPE.SELECT.key,
  }

  const formatErrorMessage = (errorMsg) => {
    if (!errorMsg) {
      return null
    }
    return (
      <div>
        {errorMsg.split('\n').map((msg, i) => (
          <div key={i}>{msg}</div>
        ))}
      </div>
    )
  }

  const onSubmit = (values, form) => {
    if (
      values.inputType === LIST_INPUT_TYPE.SELECT.key &&
      table.selectedRowKeys.length === 0
    ) {
      toast.error({
        title: `Please select at least one ${
          MEDIA_LIST_TYPE.fromKey(values.mediaType)?.name ?? values.mediaType
        }`,
      })
      return
    }

    const payload = {
      title: values.title,
    }

    if (values.inputType === LIST_INPUT_TYPE.SELECT.key) {
      payload.input = table.selectedRowKeys
      payload.type = 'self'
    }

    if (values.inputType === LIST_INPUT_TYPE.MANUAL.key) {
      payload.input = values.items || []
      payload.type = 'self'
    }

    if (values.inputType === LIST_INPUT_TYPE.UPLOAD.key) {
      payload.input = values.uploadState.data.data
      payload.type = 'uploaded'
    }

    return api.mediaList.create(values.mediaType, payload).then(
      (res) => {
        if (res.data?.success) {
          queryClient.invalidateQueries({
            queryKey: ['media-lists', values.mediaType],
          })
          toast.success({ title: 'Media list created.' })
          form.initialize(values)
          setTimeout(() => navigate(`/medialist/${values.mediaType}`))
        } else {
          toast.error({
            title:
              formatErrorMessage(res.data?.msg) ||
              'Unable to create this media list. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  const onRefetch = () => {
    response.refetch()
    table.setSelectedRowKeys([])
  }

  const onSelect = (val, form, values) => {
    const vals = val?.split(',') || []
    const oldVal = values.items || []
    const newVal = [...oldVal, ...vals]
    form.change('items', newVal)
  }

  const onInputTypeChange = (form) => {
    table.setSelectedRowKeys([])
    form.change('items', [])
    form.change('uploadState', { state: 'never' })
  }

  if (!MEDIA_LIST_TYPE.includes(mediaType)) {
    return <FourOhFour />
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateMediaListForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, form, dirty, values }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Create Media List'
            actions={
              <>
                {values.inputType === LIST_INPUT_TYPE.SELECT.key ? (
                  <>
                    <PageSearch value={searchQuery} onChange={setSearchQuery} />
                    <Button
                      onClick={onRefetch}
                      icon={<ReloadOutlined />}
                      style={{ flex: 'none' }}
                    />
                  </>
                ) : null}
                <Link to={`/medialist/${mediaType}`}>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={submitting}
                  disabled={!dirty}
                >
                  Create
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3} height={['auto', 'auto', 'calc(100vh - 130px)']}>
            <Grid gap={3} columns={[1, 1, '380px 1fr']} height='100%'>
              <Card>
                <MediaListForm
                  count={table.selectedRowKeys.length}
                  showCount={values.inputType === LIST_INPUT_TYPE.SELECT.key}
                  onMediaTypeChange={(val) => setMediaType(val)}
                  onInputTypeChange={() => onInputTypeChange(form)}
                />
              </Card>
              <Card>
                {values.inputType === LIST_INPUT_TYPE.SELECT.key ? (
                  <Table
                    rowKey={rowKey}
                    dataSource={table.data}
                    columns={attachMediaColumns}
                    loading={response.isFetching}
                    scroll={scroll}
                    rowSelection={{
                      selectedRowKeys: table.selectedRowKeys,
                      onChange: table.setSelectedRowKeys,
                      columnWidth: 40,
                      preserveSelectedRowKeys: true,
                    }}
                    pagination={{
                      ...table.query.pagination,
                      hideOnSinglePage: false,
                      total: table.total,
                    }}
                    onChange={(e) => {
                      table.setPagination(e.pagination)
                      table.setSorter(e.sorter)
                    }}
                  />
                ) : null}
                {values.inputType === LIST_INPUT_TYPE.MANUAL.key ? (
                  <Box className='hide-dropdown'>
                    <Box my={2}>
                      Enter multiple values separated by a comma (,) or a single
                      value by pressing enter.
                    </Box>
                    <Field name='items'>
                      <SelectField
                        mode='tags'
                        keyField='id'
                        textField='name'
                        placeholder='Enter items'
                        data={[]}
                        onSelect={(val) => onSelect(val, form, values)}
                        disabled={submitting}
                        allowClear
                      />
                    </Field>
                  </Box>
                ) : null}
                {values.inputType === LIST_INPUT_TYPE.UPLOAD.key ? (
                  <MediaListUploadForm />
                ) : null}
              </Card>
            </Grid>
          </Box>
        </form>
      )}
    </Form>
  )
}

CreateMediaList.propTypes = {
  isUpload: PropTypes.bool,
}

export default CreateMediaList
