import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../../client-api'
import { DSP_STATUS } from '../../../../enums'
import { PageHeader, DirtyPrompt } from '../../../../components'
import { getErrorMessage } from '../../../../utils'
import { validateDspForm } from '../@utils'

import { DspForm } from '.'

const CreateDsp = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const initialValues = {
    status: DSP_STATUS.ENABLED.key,
  }

  const onSubmit = (values, form) => {
    return api.admin.dsp.create(values).then(
      () => {
        toast.success({ title: 'DSP created.' })
        form.initialize(values)
        setTimeout(() => {
          navigate('..', { relative: 'path' })
        }, 300)
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateDspForm}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, dirty, form }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Create DSP'
            actions={
              <>
                <Button onClick={() => form.reset()} disabled={!dirty}>
                  Reset
                </Button>
                <Button
                  type='primary'
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3} width={[1, 1, 2 / 3]}>
            <DspForm />
          </Box>
        </form>
      )}
    </Form>
  )
}

export default CreateDsp
